var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "row page-titles" }, [
      _c("div", { staticClass: "col-md-6 col-8 align-self-center" }, [
        _c("h3", { staticClass: "text-themecolor m-b-0 m-t-0" }, [
          _vm._v(_vm._s(_vm.trans("configuration.frontend_configuration")))
        ]),
        _vm._v(" "),
        _c("ol", { staticClass: "breadcrumb" }, [
          _c(
            "li",
            { staticClass: "breadcrumb-item" },
            [
              _c("router-link", { attrs: { to: "/home" } }, [
                _vm._v(_vm._s(_vm.trans("general.home")))
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c("li", { staticClass: "breadcrumb-item active" }, [
            _vm._v(_vm._s(_vm.trans("configuration.frontend_configuration")))
          ])
        ])
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12" }, [
        _c("div", { staticClass: "card" }, [
          _c("div", { staticClass: "card-body" }, [
            _c("h4", { staticClass: "card-title" }, [
              _vm._v(_vm._s(_vm.trans("configuration.frontend_configuration")))
            ]),
            _vm._v(" "),
            _c(
              "form",
              {
                on: {
                  submit: function($event) {
                    $event.preventDefault()
                    return _vm.submit.apply(null, arguments)
                  },
                  keydown: function($event) {
                    return _vm.configForm.errors.clear($event.target.name)
                  }
                }
              },
              [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-12 col-sm-4" }, [
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v(_vm._s(_vm.trans("configuration.website")))
                        ]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.configForm.website,
                              expression: "configForm.website"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: {
                            type: "text",
                            value: "",
                            name: "website",
                            placeholder: _vm.trans("configuration.website")
                          },
                          domProps: { value: _vm.configForm.website },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.configForm,
                                "website",
                                $event.target.value
                              )
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c("show-error", {
                          attrs: {
                            "form-name": _vm.configForm,
                            "prop-name": "website"
                          }
                        })
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-12 col-sm-4" }, [
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v(_vm._s(_vm.trans("configuration.admin_email")))
                        ]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.configForm.admin_email,
                              expression: "configForm.admin_email"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: {
                            type: "text",
                            value: "",
                            name: "admin_email",
                            placeholder: _vm.trans("configuration.admin_email")
                          },
                          domProps: { value: _vm.configForm.admin_email },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.configForm,
                                "admin_email",
                                $event.target.value
                              )
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c("show-error", {
                          attrs: {
                            "form-name": _vm.configForm,
                            "prop-name": "admin_email"
                          }
                        })
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-12 col-sm-4" }, [
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v(
                            _vm._s(_vm.trans("configuration.support_email"))
                          )
                        ]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.configForm.support_email,
                              expression: "configForm.support_email"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: {
                            type: "text",
                            value: "",
                            name: "support_email",
                            placeholder: _vm.trans(
                              "configuration.support_email"
                            )
                          },
                          domProps: { value: _vm.configForm.support_email },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.configForm,
                                "support_email",
                                $event.target.value
                              )
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c("show-error", {
                          attrs: {
                            "form-name": _vm.configForm,
                            "prop-name": "support_email"
                          }
                        })
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-12 col-sm-3" }, [
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v(
                            _vm._s(_vm.trans("configuration.skype_account"))
                          )
                        ]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.configForm.skype_account,
                              expression: "configForm.skype_account"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: {
                            type: "text",
                            value: "",
                            name: "skype_account",
                            placeholder: _vm.trans(
                              "configuration.skype_account"
                            )
                          },
                          domProps: { value: _vm.configForm.skype_account },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.configForm,
                                "skype_account",
                                $event.target.value
                              )
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c("show-error", {
                          attrs: {
                            "form-name": _vm.configForm,
                            "prop-name": "skype_account"
                          }
                        })
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-12 col-sm-3" }, [
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v(
                            _vm._s(_vm.trans("configuration.twitter_account"))
                          )
                        ]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.configForm.twitter_account,
                              expression: "configForm.twitter_account"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: {
                            type: "text",
                            value: "",
                            name: "twitter_account",
                            placeholder: _vm.trans(
                              "configuration.twitter_account"
                            )
                          },
                          domProps: { value: _vm.configForm.twitter_account },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.configForm,
                                "twitter_account",
                                $event.target.value
                              )
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c("show-error", {
                          attrs: {
                            "form-name": _vm.configForm,
                            "prop-name": "twitter_account"
                          }
                        })
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-12 col-sm-3" }, [
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v(
                            _vm._s(_vm.trans("configuration.facebook_account"))
                          )
                        ]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.configForm.facebook_account,
                              expression: "configForm.facebook_account"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: {
                            type: "text",
                            value: "",
                            name: "facebook_account",
                            placeholder: _vm.trans(
                              "configuration.facebook_account"
                            )
                          },
                          domProps: { value: _vm.configForm.facebook_account },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.configForm,
                                "facebook_account",
                                $event.target.value
                              )
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c("show-error", {
                          attrs: {
                            "form-name": _vm.configForm,
                            "prop-name": "facebook_account"
                          }
                        })
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-12 col-sm-3" }, [
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v(
                            _vm._s(_vm.trans("configuration.github_account"))
                          )
                        ]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.configForm.github_account,
                              expression: "configForm.github_account"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: {
                            type: "text",
                            value: "",
                            name: "github_account",
                            placeholder: _vm.trans(
                              "configuration.github_account"
                            )
                          },
                          domProps: { value: _vm.configForm.github_account },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.configForm,
                                "github_account",
                                $event.target.value
                              )
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c("show-error", {
                          attrs: {
                            "form-name": _vm.configForm,
                            "prop-name": "github_account"
                          }
                        })
                      ],
                      1
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-12 col-sm-4" }, [
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", { attrs: { for: "" } }, [
                        _vm._v(
                          _vm._s(_vm.trans("configuration.enable_trial_period"))
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        [
                          _c("switches", {
                            attrs: {
                              name: "enable_trial_period",
                              theme: "bootstrap",
                              color: "success"
                            },
                            model: {
                              value: _vm.configForm.enable_trial_period,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.configForm,
                                  "enable_trial_period",
                                  $$v
                                )
                              },
                              expression: "configForm.enable_trial_period"
                            }
                          })
                        ],
                        1
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-12 col-sm-4" }, [
                    _vm.configForm.enable_trial_period
                      ? _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c("label", { attrs: { for: "" } }, [
                              _vm._v(
                                _vm._s(
                                  _vm.trans("configuration.trial_period_days")
                                )
                              )
                            ]),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.configForm.trial_period_days,
                                  expression: "configForm.trial_period_days"
                                }
                              ],
                              staticClass: "form-control",
                              attrs: {
                                type: "text",
                                value: "",
                                name: "trial_period_days",
                                placeholder: _vm.trans(
                                  "configuration.trial_period_days"
                                )
                              },
                              domProps: {
                                value: _vm.configForm.trial_period_days
                              },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.configForm,
                                    "trial_period_days",
                                    $event.target.value
                                  )
                                }
                              }
                            }),
                            _vm._v(" "),
                            _c("show-error", {
                              attrs: {
                                "form-name": _vm.configForm,
                                "prop-name": "trial_period_days"
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e()
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-12 col-sm-4" }, [
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", { attrs: { for: "" } }, [
                        _vm._v(
                          _vm._s(
                            _vm.trans("configuration.website_maintenance_mode")
                          )
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        [
                          _c("switches", {
                            attrs: {
                              name: "website_maintenance_mode",
                              theme: "bootstrap",
                              color: "success"
                            },
                            model: {
                              value: _vm.configForm.website_maintenance_mode,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.configForm,
                                  "website_maintenance_mode",
                                  $$v
                                )
                              },
                              expression: "configForm.website_maintenance_mode"
                            }
                          })
                        ],
                        1
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _vm.configForm.website_maintenance_mode
                    ? _c("div", { staticClass: "col-12 col-sm-4" }, [
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c("label", { attrs: { for: "" } }, [
                              _vm._v(
                                _vm._s(
                                  _vm.trans(
                                    "configuration.website_maintenance_mode_message"
                                  )
                                )
                              )
                            ]),
                            _vm._v(" "),
                            _c("autosize-textarea", {
                              attrs: {
                                rows: "2",
                                name: "website_maintenance_mode_message",
                                placeholder: _vm.trans(
                                  "configuration.website_maintenance_mode_message"
                                )
                              },
                              model: {
                                value:
                                  _vm.configForm
                                    .website_maintenance_mode_message,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.configForm,
                                    "website_maintenance_mode_message",
                                    $$v
                                  )
                                },
                                expression:
                                  "configForm.website_maintenance_mode_message"
                              }
                            }),
                            _vm._v(" "),
                            _c("show-error", {
                              attrs: {
                                "form-name": _vm.configForm,
                                "prop-name": "website_maintenance_mode_message"
                              }
                            })
                          ],
                          1
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.configForm.website_maintenance_mode
                    ? _c("div", { staticClass: "col-12 col-sm-4" }, [
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c(
                              "label",
                              { attrs: { for: "" } },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.trans(
                                      "configuration.website_maintenance_exclude_ip"
                                    )
                                  ) + " "
                                ),
                                _c("show-tip", {
                                  attrs: {
                                    module: "configuration",
                                    tip: "tip_website_maintenance_exclude_ip",
                                    type: "field"
                                  }
                                })
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("autosize-textarea", {
                              attrs: {
                                rows: "2",
                                name: "website_maintenance_exclude_ip",
                                placeholder: _vm.trans(
                                  "configuration.website_maintenance_exclude_ip"
                                )
                              },
                              model: {
                                value:
                                  _vm.configForm.website_maintenance_exclude_ip,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.configForm,
                                    "website_maintenance_exclude_ip",
                                    $$v
                                  )
                                },
                                expression:
                                  "configForm.website_maintenance_exclude_ip"
                              }
                            }),
                            _vm._v(" "),
                            _c("show-error", {
                              attrs: {
                                "form-name": _vm.configForm,
                                "prop-name": "website_maintenance_exclude_ip"
                              }
                            })
                          ],
                          1
                        )
                      ])
                    : _vm._e()
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c(
                      "label",
                      { attrs: { for: "" } },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.trans("configuration.unavailable_sub_domain")
                          ) + " "
                        ),
                        _c("show-tip", {
                          attrs: {
                            module: "configuration",
                            tip: "tip_unavailable_sub_domain",
                            type: "field"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("autosize-textarea", {
                      attrs: {
                        rows: "2",
                        name: "unavailable_sub_domain",
                        placeholder: _vm.trans(
                          "configuration.unavailable_sub_domain"
                        )
                      },
                      model: {
                        value: _vm.configForm.unavailable_sub_domain,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.configForm,
                            "unavailable_sub_domain",
                            $$v
                          )
                        },
                        expression: "configForm.unavailable_sub_domain"
                      }
                    }),
                    _vm._v(" "),
                    _c("show-error", {
                      attrs: {
                        "form-name": _vm.configForm,
                        "prop-name": "unavailable_sub_domain"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c("label", { attrs: { for: "" } }, [
                      _vm._v(_vm._s(_vm.trans("configuration.tnc")))
                    ]),
                    _vm._v(" "),
                    _c("html-editor", {
                      attrs: {
                        name: "website_tnc",
                        model: _vm.configForm.website_tnc,
                        isUpdate: _vm.updateTnc
                      },
                      on: {
                        "update:model": function($event) {
                          return _vm.$set(_vm.configForm, "website_tnc", $event)
                        },
                        clearErrors: function($event) {
                          return _vm.configForm.errors.clear("website_tnc")
                        }
                      }
                    }),
                    _vm._v(" "),
                    _c("show-error", {
                      attrs: {
                        "form-name": _vm.configForm,
                        "prop-name": "website_tnc"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c("label", { attrs: { for: "" } }, [
                      _vm._v(_vm._s(_vm.trans("configuration.privacy_policy")))
                    ]),
                    _vm._v(" "),
                    _c("html-editor", {
                      attrs: {
                        name: "website_privacy_policy",
                        model: _vm.configForm.website_privacy_policy,
                        isUpdate: _vm.updatePp
                      },
                      on: {
                        "update:model": function($event) {
                          return _vm.$set(
                            _vm.configForm,
                            "website_privacy_policy",
                            $event
                          )
                        },
                        clearErrors: function($event) {
                          return _vm.configForm.errors.clear(
                            "website_privacy_policy"
                          )
                        }
                      }
                    }),
                    _vm._v(" "),
                    _c("show-error", {
                      attrs: {
                        "form-name": _vm.configForm,
                        "prop-name": "website_privacy_policy"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass:
                      "btn btn-info waves-effect waves-light pull-right m-t-10",
                    attrs: { type: "submit" }
                  },
                  [_vm._v(_vm._s(_vm.trans("general.save")))]
                )
              ]
            )
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }