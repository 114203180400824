var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.proceed.apply(null, arguments)
        },
        keydown: function($event) {
          return _vm.currencyForm.errors.clear($event.target.name)
        }
      }
    },
    [
      _c(
        "div",
        { staticClass: "form-group" },
        [
          _c("label", { attrs: { for: "" } }, [
            _vm._v(_vm._s(_vm.trans("currency.name")) + " ")
          ]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.currencyForm.name,
                expression: "currencyForm.name"
              }
            ],
            staticClass: "form-control",
            attrs: {
              type: "text",
              value: "",
              name: "name",
              placeholder: _vm.trans("currency.name")
            },
            domProps: { value: _vm.currencyForm.name },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.currencyForm, "name", $event.target.value)
              }
            }
          }),
          _vm._v(" "),
          _c("show-error", {
            attrs: { "form-name": _vm.currencyForm, "prop-name": "name" }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "form-group" },
        [
          _c("label", { attrs: { for: "" } }, [
            _vm._v(_vm._s(_vm.trans("currency.symbol")))
          ]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.currencyForm.symbol,
                expression: "currencyForm.symbol"
              }
            ],
            staticClass: "form-control",
            attrs: {
              type: "text",
              value: "",
              name: "symbol",
              placeholder: _vm.trans("currency.symbol")
            },
            domProps: { value: _vm.currencyForm.symbol },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.currencyForm, "symbol", $event.target.value)
              }
            }
          }),
          _vm._v(" "),
          _c("show-error", {
            attrs: { "form-name": _vm.currencyForm, "prop-name": "symbol" }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "form-group" },
        [
          _c("div", { staticClass: "radio radio-success" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.currencyForm.position,
                  expression: "currencyForm.position"
                }
              ],
              attrs: {
                type: "radio",
                value: "prefix",
                id: "position_prefix",
                name: "position"
              },
              domProps: {
                checked: _vm.currencyForm.position === "prefix",
                checked: _vm._q(_vm.currencyForm.position, "prefix")
              },
              on: {
                click: function($event) {
                  return _vm.currencyForm.errors.clear("position")
                },
                change: function($event) {
                  return _vm.$set(_vm.currencyForm, "position", "prefix")
                }
              }
            }),
            _vm._v(" "),
            _c("label", { attrs: { for: "position_prefix" } }, [
              _vm._v(_vm._s(_vm.trans("currency.prefix")))
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "radio radio-success" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.currencyForm.position,
                  expression: "currencyForm.position"
                }
              ],
              attrs: {
                type: "radio",
                value: "suffix",
                id: "position_suffix",
                name: "position"
              },
              domProps: {
                checked: _vm.currencyForm.position === "suffix",
                checked: _vm._q(_vm.currencyForm.position, "suffix")
              },
              on: {
                click: function($event) {
                  return _vm.currencyForm.errors.clear("position")
                },
                change: function($event) {
                  return _vm.$set(_vm.currencyForm, "position", "suffix")
                }
              }
            }),
            _vm._v(" "),
            _c("label", { attrs: { for: "position_suffix" } }, [
              _vm._v(_vm._s(_vm.trans("currency.suffix")))
            ])
          ]),
          _vm._v(" "),
          _c("show-error", {
            attrs: { "form-name": _vm.currencyForm, "prop-name": "position" }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "form-group" },
        [
          _c("label", { attrs: { for: "" } }, [
            _vm._v(_vm._s(_vm.trans("currency.decimal_place")))
          ]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.currencyForm.decimal_place,
                expression: "currencyForm.decimal_place"
              }
            ],
            staticClass: "form-control",
            attrs: {
              type: "number",
              value: "",
              name: "decimal_place",
              placeholder: _vm.trans("currency.decimal_place")
            },
            domProps: { value: _vm.currencyForm.decimal_place },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.currencyForm, "decimal_place", $event.target.value)
              }
            }
          }),
          _vm._v(" "),
          _c("show-error", {
            attrs: {
              "form-name": _vm.currencyForm,
              "prop-name": "decimal_place"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "form-group" },
        [
          _c("switches", {
            staticClass: "m-l-20",
            attrs: { theme: "bootstrap", color: "success" },
            model: {
              value: _vm.currencyForm.is_default,
              callback: function($$v) {
                _vm.$set(_vm.currencyForm, "is_default", $$v)
              },
              expression: "currencyForm.is_default"
            }
          }),
          _vm._v(" " + _vm._s(_vm.trans("currency.default")) + "\n    ")
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "btn btn-info waves-effect waves-light pull-right",
          attrs: { type: "submit" }
        },
        [
          _vm.id
            ? _c("span", [_vm._v(_vm._s(_vm.trans("general.update")))])
            : _c("span", [_vm._v(_vm._s(_vm.trans("general.save")))])
        ]
      ),
      _vm._v(" "),
      _c(
        "router-link",
        {
          directives: [
            { name: "show", rawName: "v-show", value: _vm.id, expression: "id" }
          ],
          staticClass:
            "btn btn-danger waves-effect waves-light pull-right m-r-10",
          attrs: { to: "/currency" }
        },
        [_vm._v(_vm._s(_vm.trans("general.cancel")))]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }