var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { attrs: { id: "wrapper" } }, [
    _c(
      "div",
      {
        staticClass: "login-register",
        staticStyle: { "background-image": "url(/images/background.jpg)" }
      },
      [
        _c(
          "div",
          { staticClass: "login-box card" },
          [
            _c("div", { staticClass: "card-body" }, [
              _c(
                "form",
                {
                  staticClass: "form-horizontal form-material",
                  attrs: { id: "loginform" },
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.submit.apply(null, arguments)
                    },
                    keydown: function($event) {
                      return _vm.loginForm.errors.clear($event.target.name)
                    }
                  }
                },
                [
                  _c("h3", { staticClass: "box-title m-b-20" }, [
                    _vm._v(_vm._s(_vm.trans("auth.login")))
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "form-group " },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.loginForm.email,
                            expression: "loginForm.email"
                          }
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          name: "email",
                          placeholder: _vm.trans("auth.email")
                        },
                        domProps: { value: _vm.loginForm.email },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.loginForm,
                              "email",
                              $event.target.value
                            )
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c("show-error", {
                        attrs: {
                          "form-name": _vm.loginForm,
                          "prop-name": "email"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.loginForm.password,
                            expression: "loginForm.password"
                          }
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "password",
                          name: "password",
                          placeholder: _vm.trans("auth.password")
                        },
                        domProps: { value: _vm.loginForm.password },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.loginForm,
                              "password",
                              $event.target.value
                            )
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c("show-error", {
                        attrs: {
                          "form-name": _vm.loginForm,
                          "prop-name": "password"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group text-center m-t-20" }, [
                    _c(
                      "button",
                      {
                        staticClass:
                          "btn btn-info btn-lg btn-block text-uppercase waves-effect waves-light",
                        attrs: { type: "submit" }
                      },
                      [_vm._v(_vm._s(_vm.trans("auth.sign_in")))]
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group m-b-0" }, [
                    _c("div", { staticClass: "col-sm-12 text-center" }, [
                      _vm.getConfig("reset_password")
                        ? _c(
                            "p",
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.trans("auth.forgot_your_password?")
                                ) + " "
                              ),
                              _c(
                                "router-link",
                                {
                                  staticClass: "text-info m-l-5",
                                  attrs: { to: "/password" }
                                },
                                [
                                  _c("b", [
                                    _vm._v(
                                      _vm._s(_vm.trans("auth.reset_here!"))
                                    )
                                  ])
                                ]
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ])
                  ]),
                  _vm._v(" "),
                  !_vm.getConfig("mode")
                    ? _c("div", { staticClass: "row m-t-10" }, [
                        _c("div", { staticClass: "col-12 text-center" }, [
                          _c(
                            "button",
                            {
                              staticClass: "btn bt-block btn-info",
                              attrs: { type: "button" },
                              on: { click: _vm.loginAsAdmin }
                            },
                            [_vm._v("Login as Admin")]
                          )
                        ])
                      ])
                    : _vm._e()
                ]
              )
            ]),
            _vm._v(" "),
            _c("guest-footer")
          ],
          1
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }