var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { attrs: { id: "wrapper" } }, [
    _c(
      "div",
      {
        staticClass: "install",
        staticStyle: { "background-image": "url(/images/background.jpg)" }
      },
      [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.is_processing,
                expression: "!is_processing"
              }
            ],
            staticClass: "install-box card"
          },
          [
            _c(
              "form-wizard",
              {
                attrs: {
                  color: "#55CE63",
                  title: _vm.title,
                  subtitle: "Installation Wizard",
                  nextButtonText: "Next",
                  backButtonText: "Back",
                  finishButtonText: "Finish"
                },
                on: { "on-complete": _vm.finishInstallation }
              },
              [
                _c(
                  "tab-content",
                  {
                    attrs: {
                      title: "Pre Requisite Check",
                      "before-change": _vm.preRequisiteFulfill
                    }
                  },
                  [
                    _c("h4", { staticClass: "card-title" }, [
                      _vm._v(_vm._s(_vm.trans("install.server_requirements")))
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "row" },
                      _vm._l(_vm.server_checks, function(server_check) {
                        return _vm.server_checks.length
                          ? _c("div", { staticClass: "col-4" }, [
                              _c(
                                "p",
                                {
                                  class: [
                                    "alert",
                                    "alert-" +
                                      (server_check.type === "error"
                                        ? "danger"
                                        : "success")
                                  ],
                                  staticStyle: {
                                    "font-size": "13px",
                                    padding: "5px"
                                  }
                                },
                                [
                                  _c("i", {
                                    class: [
                                      "fa",
                                      "fa-" +
                                        (server_check.type === "error"
                                          ? "times"
                                          : "check")
                                    ]
                                  }),
                                  _vm._v(" " + _vm._s(server_check.message))
                                ]
                              )
                            ])
                          : _vm._e()
                      }),
                      0
                    ),
                    _vm._v(" "),
                    _c("h4", { staticClass: "card-title" }, [
                      _vm._v(_vm._s(_vm.trans("install.folder_permissions")))
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "row" },
                      _vm._l(_vm.folder_checks, function(folder_check) {
                        return _vm.folder_checks.length
                          ? _c("div", { staticClass: "col-4" }, [
                              _c(
                                "p",
                                {
                                  class: [
                                    "alert",
                                    "alert-" +
                                      (folder_check.type === "error"
                                        ? "danger"
                                        : "success")
                                  ],
                                  staticStyle: {
                                    "font-size": "13px",
                                    padding: "5px"
                                  }
                                },
                                [
                                  _c("i", {
                                    class: [
                                      "fa",
                                      "fa-" +
                                        (folder_check.type === "error"
                                          ? "times"
                                          : "check")
                                    ]
                                  }),
                                  _vm._v(" " + _vm._s(folder_check.message))
                                ]
                              )
                            ])
                          : _vm._e()
                      }),
                      0
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "tab-content",
                  {
                    attrs: {
                      title: "Database Setup",
                      "before-change": _vm.validateDatabase
                    }
                  },
                  [
                    _c(
                      "form",
                      {
                        staticClass: "form-horizontal",
                        on: {
                          keydown: function($event) {
                            return _vm.installForm.errors.clear(
                              $event.target.name
                            )
                          }
                        }
                      },
                      [
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col-6" }, [
                            _c("div", { staticClass: "row" }, [
                              _c("div", { staticClass: "col-6" }, [
                                _c(
                                  "div",
                                  { staticClass: "form-group " },
                                  [
                                    _c("label", { attrs: { for: "" } }, [
                                      _vm._v(
                                        _vm._s(_vm.trans("install.db_host"))
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.installForm.db_host,
                                          expression: "installForm.db_host"
                                        }
                                      ],
                                      staticClass: "form-control",
                                      attrs: {
                                        type: "text",
                                        name: "db_host",
                                        placeholder: _vm.trans(
                                          "install.db_host"
                                        )
                                      },
                                      domProps: {
                                        value: _vm.installForm.db_host
                                      },
                                      on: {
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            _vm.installForm,
                                            "db_host",
                                            $event.target.value
                                          )
                                        }
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c("show-error", {
                                      attrs: {
                                        "form-name": _vm.installForm,
                                        "prop-name": "db_host"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "col-6" }, [
                                _c(
                                  "div",
                                  { staticClass: "form-group " },
                                  [
                                    _c("label", { attrs: { for: "" } }, [
                                      _vm._v(
                                        _vm._s(_vm.trans("install.db_port"))
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.installForm.db_port,
                                          expression: "installForm.db_port"
                                        }
                                      ],
                                      staticClass: "form-control",
                                      attrs: {
                                        type: "text",
                                        name: "db_port",
                                        placeholder: _vm.trans(
                                          "install.db_port"
                                        )
                                      },
                                      domProps: {
                                        value: _vm.installForm.db_port
                                      },
                                      on: {
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            _vm.installForm,
                                            "db_port",
                                            $event.target.value
                                          )
                                        }
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c("show-error", {
                                      attrs: {
                                        "form-name": _vm.installForm,
                                        "prop-name": "db_port"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ])
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "form-group " },
                              [
                                _c("label", { attrs: { for: "" } }, [
                                  _vm._v(
                                    _vm._s(_vm.trans("install.db_database"))
                                  )
                                ]),
                                _vm._v(" "),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.installForm.db_database,
                                      expression: "installForm.db_database"
                                    }
                                  ],
                                  staticClass: "form-control",
                                  attrs: {
                                    type: "text",
                                    name: "db_database",
                                    placeholder: _vm.trans(
                                      "install.db_database"
                                    )
                                  },
                                  domProps: {
                                    value: _vm.installForm.db_database
                                  },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.installForm,
                                        "db_database",
                                        $event.target.value
                                      )
                                    }
                                  }
                                }),
                                _vm._v(" "),
                                _c("show-error", {
                                  attrs: {
                                    "form-name": _vm.installForm,
                                    "prop-name": "db_database"
                                  }
                                })
                              ],
                              1
                            )
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-6" }, [
                            _c(
                              "div",
                              { staticClass: "form-group " },
                              [
                                _c("label", { attrs: { for: "" } }, [
                                  _vm._v(
                                    _vm._s(_vm.trans("install.db_username"))
                                  )
                                ]),
                                _vm._v(" "),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.installForm.db_username,
                                      expression: "installForm.db_username"
                                    }
                                  ],
                                  staticClass: "form-control",
                                  attrs: {
                                    type: "text",
                                    name: "db_username",
                                    placeholder: _vm.trans(
                                      "install.db_username"
                                    )
                                  },
                                  domProps: {
                                    value: _vm.installForm.db_username
                                  },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.installForm,
                                        "db_username",
                                        $event.target.value
                                      )
                                    }
                                  }
                                }),
                                _vm._v(" "),
                                _c("show-error", {
                                  attrs: {
                                    "form-name": _vm.installForm,
                                    "prop-name": "db_username"
                                  }
                                })
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "form-group " },
                              [
                                _c("label", { attrs: { for: "" } }, [
                                  _vm._v(
                                    _vm._s(_vm.trans("install.db_password"))
                                  )
                                ]),
                                _vm._v(" "),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.installForm.db_password,
                                      expression: "installForm.db_password"
                                    }
                                  ],
                                  staticClass: "form-control",
                                  attrs: {
                                    type: "password",
                                    name: "db_password",
                                    placeholder: _vm.trans(
                                      "install.db_password"
                                    )
                                  },
                                  domProps: {
                                    value: _vm.installForm.db_password
                                  },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.installForm,
                                        "db_password",
                                        $event.target.value
                                      )
                                    }
                                  }
                                }),
                                _vm._v(" "),
                                _c("show-error", {
                                  attrs: {
                                    "form-name": _vm.installForm,
                                    "prop-name": "db_password"
                                  }
                                })
                              ],
                              1
                            )
                          ])
                        ])
                      ]
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "tab-content",
                  {
                    attrs: {
                      title: "User Setup",
                      "before-change": _vm.validateAdmin
                    }
                  },
                  [
                    _c(
                      "form",
                      {
                        staticClass: "form-horizontal",
                        on: {
                          keydown: function($event) {
                            return _vm.installForm.errors.clear(
                              $event.target.name
                            )
                          }
                        }
                      },
                      [
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col-6" }, [
                            _c(
                              "div",
                              { staticClass: "form-group " },
                              [
                                _c("label", { attrs: { for: "" } }, [
                                  _vm._v(_vm._s(_vm.trans("user.first_name")))
                                ]),
                                _vm._v(" "),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.installForm.first_name,
                                      expression: "installForm.first_name"
                                    }
                                  ],
                                  staticClass: "form-control",
                                  attrs: {
                                    type: "text",
                                    name: "first_name",
                                    placeholder: _vm.trans("user.first_name")
                                  },
                                  domProps: {
                                    value: _vm.installForm.first_name
                                  },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.installForm,
                                        "first_name",
                                        $event.target.value
                                      )
                                    }
                                  }
                                }),
                                _vm._v(" "),
                                _c("show-error", {
                                  attrs: {
                                    "form-name": _vm.installForm,
                                    "prop-name": "first_name"
                                  }
                                })
                              ],
                              1
                            )
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-6" }, [
                            _c(
                              "div",
                              { staticClass: "form-group " },
                              [
                                _c("label", { attrs: { for: "" } }, [
                                  _vm._v(_vm._s(_vm.trans("user.last_name")))
                                ]),
                                _vm._v(" "),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.installForm.last_name,
                                      expression: "installForm.last_name"
                                    }
                                  ],
                                  staticClass: "form-control",
                                  attrs: {
                                    type: "text",
                                    name: "last_name",
                                    placeholder: _vm.trans("user.last_name")
                                  },
                                  domProps: {
                                    value: _vm.installForm.last_name
                                  },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.installForm,
                                        "last_name",
                                        $event.target.value
                                      )
                                    }
                                  }
                                }),
                                _vm._v(" "),
                                _c("show-error", {
                                  attrs: {
                                    "form-name": _vm.installForm,
                                    "prop-name": "last_name"
                                  }
                                })
                              ],
                              1
                            )
                          ])
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "form-group " },
                          [
                            _c("label", { attrs: { for: "" } }, [
                              _vm._v(_vm._s(_vm.trans("user.email")))
                            ]),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.installForm.email,
                                  expression: "installForm.email"
                                }
                              ],
                              staticClass: "form-control",
                              attrs: {
                                type: "text",
                                name: "email",
                                placeholder: _vm.trans("user.email")
                              },
                              domProps: { value: _vm.installForm.email },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.installForm,
                                    "email",
                                    $event.target.value
                                  )
                                }
                              }
                            }),
                            _vm._v(" "),
                            _c("show-error", {
                              attrs: {
                                "form-name": _vm.installForm,
                                "prop-name": "email"
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col-6" }, [
                            _c(
                              "div",
                              { staticClass: "form-group " },
                              [
                                _c("label", { attrs: { for: "" } }, [
                                  _vm._v(_vm._s(_vm.trans("auth.password")))
                                ]),
                                _vm._v(" "),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.installForm.password,
                                      expression: "installForm.password"
                                    }
                                  ],
                                  staticClass: "form-control",
                                  attrs: {
                                    type: "password",
                                    name: "password",
                                    placeholder: _vm.trans("auth.password")
                                  },
                                  domProps: { value: _vm.installForm.password },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.installForm,
                                        "password",
                                        $event.target.value
                                      )
                                    }
                                  }
                                }),
                                _vm._v(" "),
                                _c("show-error", {
                                  attrs: {
                                    "form-name": _vm.installForm,
                                    "prop-name": "password"
                                  }
                                })
                              ],
                              1
                            )
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-6" }, [
                            _c(
                              "div",
                              { staticClass: "form-group " },
                              [
                                _c("label", { attrs: { for: "" } }, [
                                  _vm._v(
                                    _vm._s(_vm.trans("auth.confirm_password"))
                                  )
                                ]),
                                _vm._v(" "),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value:
                                        _vm.installForm.password_confirmation,
                                      expression:
                                        "installForm.password_confirmation"
                                    }
                                  ],
                                  staticClass: "form-control",
                                  attrs: {
                                    type: "password",
                                    name: "password_confirmation",
                                    placeholder: _vm.trans(
                                      "auth.confirm_password"
                                    )
                                  },
                                  domProps: {
                                    value: _vm.installForm.password_confirmation
                                  },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.installForm,
                                        "password_confirmation",
                                        $event.target.value
                                      )
                                    }
                                  }
                                }),
                                _vm._v(" "),
                                _c("show-error", {
                                  attrs: {
                                    "form-name": _vm.installForm,
                                    "prop-name": "password_confirmation"
                                  }
                                })
                              ],
                              1
                            )
                          ])
                        ])
                      ]
                    )
                  ]
                ),
                _vm._v(" "),
                _c("tab-content", { attrs: { title: "Ready to GO!" } }, [
                  _c("h4", { staticClass: "card-subtitle text-center" }, [
                    _vm._v(_vm._s(_vm.trans("install.environment_setup")))
                  ]),
                  _vm._v(" "),
                  _c(
                    "form",
                    {
                      staticClass: "form-horizontal",
                      on: {
                        keydown: function($event) {
                          return _vm.installForm.errors.clear(
                            $event.target.name
                          )
                        }
                      }
                    },
                    [
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-12 col-sm-6" }, [
                          _c(
                            "div",
                            { staticClass: "form-group " },
                            [
                              _c("label", { attrs: { for: "" } }, [
                                _vm._v(_vm._s(_vm.trans("install.app_name")))
                              ]),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.installForm.app_name,
                                    expression: "installForm.app_name"
                                  }
                                ],
                                staticClass: "form-control",
                                attrs: {
                                  type: "text",
                                  name: "app_name",
                                  placeholder: _vm.trans("install.app_name")
                                },
                                domProps: { value: _vm.installForm.app_name },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.installForm,
                                      "app_name",
                                      $event.target.value
                                    )
                                  }
                                }
                              }),
                              _vm._v(" "),
                              _c("show-error", {
                                attrs: {
                                  "form-name": _vm.installForm,
                                  "prop-name": "app_name"
                                }
                              })
                            ],
                            1
                          )
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-12 col-sm-6" }, [
                          _c(
                            "div",
                            { staticClass: "form-group " },
                            [
                              _c("label", { attrs: { for: "" } }, [
                                _vm._v(
                                  _vm._s(_vm.trans("install.encryption_key"))
                                )
                              ]),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.installForm.encryption_key,
                                    expression: "installForm.encryption_key"
                                  }
                                ],
                                staticClass: "form-control",
                                attrs: {
                                  type: "text",
                                  name: "encryption_key",
                                  placeholder: _vm.trans(
                                    "install.encryption_key"
                                  )
                                },
                                domProps: {
                                  value: _vm.installForm.encryption_key
                                },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.installForm,
                                      "encryption_key",
                                      $event.target.value
                                    )
                                  }
                                }
                              }),
                              _vm._v(" "),
                              _c("show-error", {
                                attrs: {
                                  "form-name": _vm.installForm,
                                  "prop-name": "encryption_key"
                                }
                              })
                            ],
                            1
                          )
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-12 col-sm-4" }, [
                          _c(
                            "div",
                            { staticClass: "form-group " },
                            [
                              _c("label", { attrs: { for: "" } }, [
                                _vm._v(_vm._s(_vm.trans("install.website_url")))
                              ]),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.installForm.website_url,
                                    expression: "installForm.website_url"
                                  }
                                ],
                                staticClass: "form-control",
                                attrs: {
                                  type: "text",
                                  name: "website_url",
                                  placeholder: _vm.trans("install.website_url")
                                },
                                domProps: {
                                  value: _vm.installForm.website_url
                                },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.installForm,
                                      "website_url",
                                      $event.target.value
                                    )
                                  }
                                }
                              }),
                              _vm._v(" "),
                              _c("show-error", {
                                attrs: {
                                  "form-name": _vm.installForm,
                                  "prop-name": "website_url"
                                }
                              })
                            ],
                            1
                          )
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-12 col-sm-4" }, [
                          _c(
                            "div",
                            { staticClass: "form-group " },
                            [
                              _c("label", { attrs: { for: "" } }, [
                                _vm._v(_vm._s(_vm.trans("install.admin_url")))
                              ]),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.installForm.admin_url,
                                    expression: "installForm.admin_url"
                                  }
                                ],
                                staticClass: "form-control",
                                attrs: {
                                  type: "text",
                                  name: "admin_url",
                                  placeholder: _vm.trans("install.admin_url")
                                },
                                domProps: { value: _vm.installForm.admin_url },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.installForm,
                                      "admin_url",
                                      $event.target.value
                                    )
                                  }
                                }
                              }),
                              _vm._v(" "),
                              _c("show-error", {
                                attrs: {
                                  "form-name": _vm.installForm,
                                  "prop-name": "admin_url"
                                }
                              })
                            ],
                            1
                          )
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-12 col-sm-4" }, [
                          _c(
                            "div",
                            { staticClass: "form-group " },
                            [
                              _c("label", { attrs: { for: "" } }, [
                                _vm._v(_vm._s(_vm.trans("install.app_url")))
                              ]),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.installForm.app_url,
                                    expression: "installForm.app_url"
                                  }
                                ],
                                staticClass: "form-control",
                                attrs: {
                                  type: "text",
                                  name: "app_url",
                                  placeholder: _vm.trans("install.app_url")
                                },
                                domProps: { value: _vm.installForm.app_url },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.installForm,
                                      "app_url",
                                      $event.target.value
                                    )
                                  }
                                }
                              }),
                              _vm._v(" "),
                              _c("show-error", {
                                attrs: {
                                  "form-name": _vm.installForm,
                                  "prop-name": "app_url"
                                }
                              })
                            ],
                            1
                          )
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-12 col-sm-6" }, [
                          _c(
                            "div",
                            { staticClass: "form-group " },
                            [
                              _c("label", { attrs: { for: "" } }, [
                                _vm._v(_vm._s(_vm.trans("install.access_code")))
                              ]),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.installForm.access_code,
                                    expression: "installForm.access_code"
                                  }
                                ],
                                staticClass: "form-control",
                                attrs: {
                                  type: "text",
                                  name: "access_code",
                                  placeholder: _vm.trans("install.access_code")
                                },
                                domProps: {
                                  value: _vm.installForm.access_code
                                },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.installForm,
                                      "access_code",
                                      $event.target.value
                                    )
                                  }
                                }
                              }),
                              _vm._v(" "),
                              _c("show-error", {
                                attrs: {
                                  "form-name": _vm.installForm,
                                  "prop-name": "access_code"
                                }
                              })
                            ],
                            1
                          )
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-12 col-sm-6" }, [
                          _c(
                            "div",
                            { staticClass: "form-group " },
                            [
                              _c("label", { attrs: { for: "" } }, [
                                _vm._v(
                                  _vm._s(_vm.trans("install.envato_email"))
                                )
                              ]),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.installForm.envato_email,
                                    expression: "installForm.envato_email"
                                  }
                                ],
                                staticClass: "form-control",
                                attrs: {
                                  type: "text",
                                  name: "envato_email",
                                  placeholder: _vm.trans("install.envato_email")
                                },
                                domProps: {
                                  value: _vm.installForm.envato_email
                                },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.installForm,
                                      "envato_email",
                                      $event.target.value
                                    )
                                  }
                                }
                              }),
                              _vm._v(" "),
                              _c("show-error", {
                                attrs: {
                                  "form-name": _vm.installForm,
                                  "prop-name": "envato_email"
                                }
                              })
                            ],
                            1
                          )
                        ])
                      ])
                    ]
                  )
                ])
              ],
              1
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.is_processing,
                expression: "is_processing"
              }
            ],
            staticClass: "install-box card"
          },
          [_vm._m(0)]
        )
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-body" }, [
      _c("h4", { staticClass: "card-title text-center" }, [
        _vm._v("Installation in Progress")
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "alert alert-info m-t-20" }, [
        _vm._v(
          "We are installing application for you, this process may take several minutes depending upon your server configuration. Please do not refresh this page or click on any link. You will be automatically redirected to login page once installation completes."
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }