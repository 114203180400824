var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "row page-titles" }, [
      _c("div", { staticClass: "col-md-6 col-8 align-self-center" }, [
        _c("h3", { staticClass: "text-themecolor m-b-0 m-t-0" }, [
          _vm._v(_vm._s(_vm.trans("configuration.configuration")))
        ]),
        _vm._v(" "),
        _c("ol", { staticClass: "breadcrumb" }, [
          _c(
            "li",
            { staticClass: "breadcrumb-item" },
            [
              _c("router-link", { attrs: { to: "/home" } }, [
                _vm._v(_vm._s(_vm.trans("general.home")))
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            { staticClass: "breadcrumb-item" },
            [
              _c("router-link", { attrs: { to: "/configuration/basic" } }, [
                _vm._v(_vm._s(_vm.trans("configuration.configuration")))
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c("li", { staticClass: "breadcrumb-item active" }, [
            _vm._v(_vm._s(_vm.trans("instance.instance")))
          ])
        ])
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12" }, [
        _c("div", { staticClass: "card" }, [
          _c("div", { staticClass: "card-body" }, [
            _c(
              "div",
              { staticClass: "row" },
              [
                _c("configuration-sidebar", { attrs: { menu: "instance" } }),
                _vm._v(" "),
                _c("div", { staticClass: "col-10 col-lg-10 col-md-10" }, [
                  _c(
                    "form",
                    {
                      on: {
                        submit: function($event) {
                          $event.preventDefault()
                          return _vm.submit.apply(null, arguments)
                        },
                        keydown: function($event) {
                          return _vm.configForm.errors.clear($event.target.name)
                        }
                      }
                    },
                    [
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-12 col-sm-4" }, [
                          _c("div", { staticClass: "form-group" }, [
                            _c("label", { attrs: { for: "" } }, [
                              _vm._v(
                                _vm._s(
                                  _vm.trans(
                                    "configuration.instance_maintenance_mode"
                                  )
                                )
                              )
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              [
                                _c("switches", {
                                  attrs: {
                                    name: "instance_maintenance_mode",
                                    theme: "bootstrap",
                                    color: "success"
                                  },
                                  model: {
                                    value:
                                      _vm.configForm.instance_maintenance_mode,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.configForm,
                                        "instance_maintenance_mode",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "configForm.instance_maintenance_mode"
                                  }
                                })
                              ],
                              1
                            )
                          ])
                        ]),
                        _vm._v(" "),
                        _vm.configForm.instance_maintenance_mode
                          ? _c("div", { staticClass: "col-12 col-sm-4" }, [
                              _c(
                                "div",
                                { staticClass: "form-group" },
                                [
                                  _c("label", { attrs: { for: "" } }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.trans(
                                          "configuration.instance_maintenance_mode_message"
                                        )
                                      )
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c("autosize-textarea", {
                                    attrs: {
                                      rows: "2",
                                      name: "instance_maintenance_mode_message",
                                      placeholder: _vm.trans(
                                        "configuration.instance_maintenance_mode_message"
                                      )
                                    },
                                    model: {
                                      value:
                                        _vm.configForm
                                          .instance_maintenance_mode_message,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.configForm,
                                          "instance_maintenance_mode_message",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "configForm.instance_maintenance_mode_message"
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("show-error", {
                                    attrs: {
                                      "form-name": _vm.configForm,
                                      "prop-name":
                                        "instance_maintenance_mode_message"
                                    }
                                  })
                                ],
                                1
                              )
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.configForm.instance_maintenance_mode
                          ? _c("div", { staticClass: "col-12 col-sm-4" }, [
                              _c(
                                "div",
                                { staticClass: "form-group" },
                                [
                                  _c(
                                    "label",
                                    { attrs: { for: "" } },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.trans(
                                            "configuration.instance_maintenance_exclude_ip"
                                          )
                                        ) + " "
                                      ),
                                      _c("show-tip", {
                                        attrs: {
                                          module: "configuration",
                                          tip:
                                            "tip_instance_maintenance_exclude_ip",
                                          type: "field"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("autosize-textarea", {
                                    attrs: {
                                      rows: "2",
                                      name: "instance_maintenance_exclude_ip",
                                      placeholder: _vm.trans(
                                        "configuration.instance_maintenance_exclude_ip"
                                      )
                                    },
                                    model: {
                                      value:
                                        _vm.configForm
                                          .instance_maintenance_exclude_ip,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.configForm,
                                          "instance_maintenance_exclude_ip",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "configForm.instance_maintenance_exclude_ip"
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("show-error", {
                                    attrs: {
                                      "form-name": _vm.configForm,
                                      "prop-name":
                                        "instance_maintenance_exclude_ip"
                                    }
                                  })
                                ],
                                1
                              )
                            ])
                          : _vm._e()
                      ]),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass:
                            "btn btn-info waves-effect waves-light pull-right m-t-10",
                          attrs: { type: "submit" }
                        },
                        [_vm._v(_vm._s(_vm.trans("general.save")))]
                      )
                    ]
                  )
                ])
              ],
              1
            )
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }