var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "row page-titles" }, [
      _c("div", { staticClass: "col-md-6 col-8 align-self-center" }, [
        _c("h3", { staticClass: "text-themecolor m-b-0 m-t-0" }, [
          _vm._v(_vm._s(_vm.trans("message.message")))
        ]),
        _vm._v(" "),
        _c("ol", { staticClass: "breadcrumb" }, [
          _c(
            "li",
            { staticClass: "breadcrumb-item" },
            [
              _c("router-link", { attrs: { to: "/home" } }, [
                _vm._v(_vm._s(_vm.trans("general.home")))
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            { staticClass: "breadcrumb-item" },
            [
              _c("router-link", { attrs: { to: "/message" } }, [
                _vm._v(_vm._s(_vm.trans("message.message")))
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c("li", { staticClass: "breadcrumb-item active" }, [
            _vm._v(_vm._s(_vm.trans("message.inbox")))
          ])
        ])
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12" }, [
        _c("div", { staticClass: "card" }, [
          _c("div", { staticClass: "card-body" }, [
            _c(
              "div",
              { staticClass: "row" },
              [
                _c("message-sidebar", {
                  attrs: { menu: "inbox", statistics: _vm.statistics }
                }),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-10 col-lg-10 col-md-10" },
                  [
                    _c("h4", { staticClass: "card-title" }, [
                      _vm._v(_vm._s(_vm.trans("message.inbox")))
                    ]),
                    _vm._v(" "),
                    _vm.messages
                      ? _c("h6", { staticClass: "card-subtitle" }, [
                          _vm._v(
                            _vm._s(
                              _vm.trans("general.total_result_found", {
                                count: _vm.messages.total
                              })
                            )
                          )
                        ])
                      : _c("h6", { staticClass: "card-subtitle" }, [
                          _vm._v(_vm._s(_vm.trans("general.no_result_found")))
                        ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "table-responsive" }, [
                      _vm.messages.total
                        ? _c("table", { staticClass: "table" }, [
                            _c("thead", [
                              _c("tr", [
                                _c("th", [
                                  _vm._v(_vm._s(_vm.trans("message.sender")))
                                ]),
                                _vm._v(" "),
                                _c("th", [
                                  _vm._v(_vm._s(_vm.trans("message.subject")))
                                ]),
                                _vm._v(" "),
                                _c("th"),
                                _vm._v(" "),
                                _c("th", [
                                  _vm._v(
                                    _vm._s(_vm.trans("message.received_at"))
                                  )
                                ]),
                                _vm._v(" "),
                                _c("th", { staticClass: "table-option" }, [
                                  _vm._v(_vm._s(_vm.trans("general.action")))
                                ])
                              ])
                            ]),
                            _vm._v(" "),
                            _c(
                              "tbody",
                              _vm._l(_vm.messages.data, function(inbox) {
                                return _c("tr", [
                                  _c(
                                    "td",
                                    {
                                      class: _vm.message_details[inbox.id].read
                                        ? "text-strong"
                                        : ""
                                    },
                                    [
                                      inbox.from_user_id
                                        ? _c(
                                            "span",
                                            [
                                              _vm._v(
                                                "\n                                                    " +
                                                  _vm._s(
                                                    inbox.user_from.email
                                                  ) +
                                                  "\n                                                    "
                                              ),
                                              _vm.message_details[inbox.id]
                                                .count
                                                ? [
                                                    _vm._v(
                                                      "\n                                                        (" +
                                                        _vm._s(
                                                          _vm.message_details[
                                                            inbox.id
                                                          ].count
                                                        ) +
                                                        ")\n                                                    "
                                                    )
                                                  ]
                                                : _vm._e()
                                            ],
                                            2
                                          )
                                        : _vm._e()
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("td", {
                                    domProps: {
                                      textContent: _vm._s(inbox.subject)
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("td", [
                                    inbox.has_attachment
                                      ? _c("i", {
                                          staticClass: "fas fa-paperclip"
                                        })
                                      : _vm._e()
                                  ]),
                                  _vm._v(" "),
                                  _c("td", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("momentDateTime")(
                                          inbox.created_at
                                        )
                                      )
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c("td", { staticClass: "table-option" }, [
                                    _c(
                                      "div",
                                      { staticClass: "btn-group" },
                                      [
                                        _c(
                                          "router-link",
                                          {
                                            directives: [
                                              {
                                                name: "tooltip",
                                                rawName: "v-tooltip",
                                                value: _vm.trans(
                                                  "message.view"
                                                ),
                                                expression:
                                                  "trans('message.view')"
                                              }
                                            ],
                                            staticClass: "btn btn-info btn-sm",
                                            attrs: {
                                              to: "/message/" + inbox.uuid
                                            }
                                          },
                                          [
                                            _c("i", {
                                              staticClass:
                                                "fas fa-arrow-circle-right"
                                            })
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "button",
                                          {
                                            directives: [
                                              {
                                                name: "confirm",
                                                rawName: "v-confirm",
                                                value: {
                                                  ok: _vm.confirmDelete(inbox)
                                                },
                                                expression:
                                                  "{ok: confirmDelete(inbox)}"
                                              },
                                              {
                                                name: "tooltip",
                                                rawName: "v-tooltip",
                                                value: _vm.trans(
                                                  "message.move_to_trash"
                                                ),
                                                expression:
                                                  "trans('message.move_to_trash')"
                                              }
                                            ],
                                            key: inbox.id,
                                            staticClass: "btn btn-danger btn-sm"
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "fas fa-trash"
                                            })
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ])
                                ])
                              }),
                              0
                            )
                          ])
                        : _vm._e()
                    ]),
                    _vm._v(" "),
                    _c("pagination-record", {
                      attrs: {
                        "page-length": _vm.filterMessageForm.page_length,
                        records: _vm.messages
                      },
                      on: {
                        "update:pageLength": function($event) {
                          return _vm.$set(
                            _vm.filterMessageForm,
                            "page_length",
                            $event
                          )
                        },
                        "update:page-length": function($event) {
                          return _vm.$set(
                            _vm.filterMessageForm,
                            "page_length",
                            $event
                          )
                        },
                        updateRecords: _vm.getMessages
                      },
                      nativeOn: {
                        change: function($event) {
                          return _vm.getMessages.apply(null, arguments)
                        }
                      }
                    })
                  ],
                  1
                )
              ],
              1
            )
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }