var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "row page-titles" }, [
      _c("div", { staticClass: "col-md-6 col-8 align-self-center" }, [
        _c("h3", { staticClass: "text-themecolor m-b-0 m-t-0" }, [
          _vm._v(_vm._s(_vm.trans("template.edit_template")))
        ]),
        _vm._v(" "),
        _c("ol", { staticClass: "breadcrumb" }, [
          _c(
            "li",
            { staticClass: "breadcrumb-item" },
            [
              _c("router-link", { attrs: { to: "/home" } }, [
                _vm._v(_vm._s(_vm.trans("general.home")))
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            { staticClass: "breadcrumb-item" },
            [
              _c("router-link", { attrs: { to: "/email-template" } }, [
                _vm._v(_vm._s(_vm.trans("template.email_template")))
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c("li", { staticClass: "breadcrumb-item active" }, [
            _vm._v(_vm._s(_vm.trans("template.edit_template")))
          ])
        ])
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-sm-12" }, [
        _c("div", { staticClass: "card" }, [
          _c("div", { staticClass: "card-body" }, [
            _c("h4", { staticClass: "card-title" }, [
              _vm._v(_vm._s(_vm.trans("template.edit_template")))
            ]),
            _vm._v(" "),
            _c(
              "form",
              {
                on: {
                  submit: function($event) {
                    $event.preventDefault()
                    return _vm.submit.apply(null, arguments)
                  },
                  keydown: function($event) {
                    return _vm.templateForm.errors.clear($event.target.name)
                  }
                }
              },
              [
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c("label", { attrs: { for: "" } }, [
                      _vm._v(_vm._s(_vm.trans("template.subject")))
                    ]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.templateForm.subject,
                          expression: "templateForm.subject"
                        }
                      ],
                      staticClass: "form-control",
                      attrs: {
                        type: "text",
                        value: "",
                        name: "subject",
                        placeholder: _vm.trans("template.subject")
                      },
                      domProps: { value: _vm.templateForm.subject },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.templateForm,
                            "subject",
                            $event.target.value
                          )
                        }
                      }
                    }),
                    _vm._v(" "),
                    _c("show-error", {
                      attrs: {
                        "form-name": _vm.templateForm,
                        "prop-name": "subject"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c("html-editor", {
                      attrs: {
                        name: "body",
                        model: _vm.templateForm.body,
                        isUpdate: "true"
                      },
                      on: {
                        "update:model": function($event) {
                          return _vm.$set(_vm.templateForm, "body", $event)
                        },
                        clearErrors: function($event) {
                          return _vm.templateForm.errors.clear("body")
                        }
                      }
                    }),
                    _vm._v(" "),
                    _c("show-error", {
                      attrs: {
                        "form-name": _vm.templateForm,
                        "prop-name": "body"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "help-block" }, [
                  _vm._v(
                    _vm._s(_vm.trans("template.available_fields")) +
                      ": " +
                      _vm._s(_vm.fields)
                  )
                ]),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass:
                      "btn btn-info waves-effect waves-light pull-right m-t-10",
                    attrs: { type: "submit" }
                  },
                  [_vm._v(_vm._s(_vm.trans("general.save")))]
                )
              ]
            )
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }