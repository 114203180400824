var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "row page-titles" }, [
      _c("div", { staticClass: "col-md-6 col-8 align-self-center" }, [
        _c("h3", { staticClass: "text-themecolor m-b-0 m-t-0" }, [
          _vm._v(_vm._s(_vm.trans("general.home")))
        ]),
        _vm._v(" "),
        _c("ol", { staticClass: "breadcrumb" }, [
          _c("li", { staticClass: "breadcrumb-item active" }, [
            _vm._v(_vm._s(_vm.trans("general.home")))
          ])
        ])
      ])
    ]),
    _vm._v(" "),
    _vm.hasPermission("list-instance")
      ? _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-12 col-sm-4" }, [
            _c("div", { staticClass: "card" }, [
              _c("div", { staticClass: "card-body" }, [
                _c("h4", { staticClass: "card-title" }, [
                  _vm._v(
                    _vm._s(
                      _vm.trans("instance.instance") +
                        " " +
                        _vm.trans("instance.status")
                    )
                  )
                ]),
                _vm._v(" "),
                _c("table", { staticClass: "table browser m-t-30 no-border" }, [
                  _c("tbody", [
                    _c("tr", [
                      _c("td", [_vm._v(_vm._s(_vm.trans("general.total")))]),
                      _vm._v(" "),
                      _c("td", { staticClass: "text-right" }, [
                        _c("span", { staticClass: "label label-info" }, [
                          _vm._v(_vm._s(_vm.instance_stats.total))
                        ])
                      ])
                    ]),
                    _vm._v(" "),
                    _c("tr", [
                      _c("td", [_vm._v(_vm._s(_vm.trans("instance.running")))]),
                      _vm._v(" "),
                      _c("td", { staticClass: "text-right" }, [
                        _c("span", { staticClass: "label label-success" }, [
                          _vm._v(_vm._s(_vm.instance_stats.running))
                        ])
                      ])
                    ]),
                    _vm._v(" "),
                    _c("tr", [
                      _c("td", [_vm._v(_vm._s(_vm.trans("instance.expired")))]),
                      _vm._v(" "),
                      _c("td", { staticClass: "text-right" }, [
                        _c("span", { staticClass: "label label-danger" }, [
                          _vm._v(_vm._s(_vm.instance_stats.expired))
                        ])
                      ])
                    ]),
                    _vm._v(" "),
                    _c("tr", [
                      _c("td", [
                        _vm._v(_vm._s(_vm.trans("instance.suspended")))
                      ]),
                      _vm._v(" "),
                      _c("td", { staticClass: "text-right" }, [
                        _c("span", { staticClass: "label label-warning" }, [
                          _vm._v(_vm._s(_vm.instance_stats.suspended))
                        ])
                      ])
                    ]),
                    _vm._v(" "),
                    _c("tr", [
                      _c("td", [
                        _vm._v(_vm._s(_vm.trans("instance.terminated")))
                      ]),
                      _vm._v(" "),
                      _c("td", { staticClass: "text-right" }, [
                        _c("span", { staticClass: "label label-danger" }, [
                          _vm._v(_vm._s(_vm.instance_stats.terminated))
                        ])
                      ])
                    ])
                  ])
                ])
              ])
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-12 col-sm-4" }, [
            _c("div", { staticClass: "card" }, [
              _c(
                "div",
                { staticClass: "card-body" },
                [
                  _c("h4", { staticClass: "card-title" }, [
                    _vm._v(
                      _vm._s(
                        _vm.trans("instance.instance") +
                          " " +
                          _vm.trans("general.statistics")
                      )
                    )
                  ]),
                  _vm._v(" "),
                  _c("doughnut-graph", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.graph.instance_status.labels.length,
                        expression: "graph.instance_status.labels.length"
                      }
                    ],
                    attrs: { graph: _vm.graph.instance_status }
                  }),
                  _vm._v(" "),
                  !_vm.graph.instance_status.labels.length
                    ? _c("h6", { staticClass: "card-subtitle" }, [
                        _vm._v(_vm._s(_vm.trans("general.no_result_found")))
                      ])
                    : _vm._e()
                ],
                1
              )
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-12 col-sm-4" }, [
            _c("div", { staticClass: "card" }, [
              _c("div", { staticClass: "card-body" }, [
                _c("h4", { staticClass: "card-title" }, [
                  _vm._v(
                    _vm._s(
                      _vm.trans("general.misc") +
                        " " +
                        _vm.trans("general.statistics")
                    )
                  )
                ]),
                _vm._v(" "),
                _c("table", { staticClass: "table browser m-t-30 no-border" }, [
                  _c(
                    "tbody",
                    [
                      _vm.hasPermission("list-query")
                        ? [
                            _c("tr", [
                              _c("td", [
                                _vm._v(
                                  _vm._s(
                                    _vm.trans("general.total") +
                                      " " +
                                      _vm.trans("query.query")
                                  )
                                )
                              ]),
                              _vm._v(" "),
                              _c("td", { staticClass: "text-right" }, [
                                _c(
                                  "span",
                                  { staticClass: "label label-info" },
                                  [_vm._v(_vm._s(_vm.query_stats.total))]
                                )
                              ])
                            ]),
                            _vm._v(" "),
                            _c("tr", [
                              _c("td", [
                                _vm._v(
                                  _vm._s(
                                    _vm.trans("query.replied") +
                                      " " +
                                      _vm.trans("query.query")
                                  )
                                )
                              ]),
                              _vm._v(" "),
                              _c("td", { staticClass: "text-right" }, [
                                _c(
                                  "span",
                                  { staticClass: "label label-success" },
                                  [_vm._v(_vm._s(_vm.query_stats.replied))]
                                )
                              ])
                            ]),
                            _vm._v(" "),
                            _c("tr", [
                              _c("td", [
                                _vm._v(
                                  _vm._s(
                                    _vm.trans("query.unanswered") +
                                      " " +
                                      _vm.trans("query.query")
                                  )
                                )
                              ]),
                              _vm._v(" "),
                              _c("td", { staticClass: "text-right" }, [
                                _c(
                                  "span",
                                  { staticClass: "label label-danger" },
                                  [_vm._v(_vm._s(_vm.query_stats.unanswered))]
                                )
                              ])
                            ]),
                            _vm._v(" "),
                            _c("tr", [
                              _c("td", [
                                _vm._v(
                                  _vm._s(
                                    _vm.trans("query.under_process") +
                                      " " +
                                      _vm.trans("query.query")
                                  )
                                )
                              ]),
                              _vm._v(" "),
                              _c("td", { staticClass: "text-right" }, [
                                _c(
                                  "span",
                                  { staticClass: "label label-warning" },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.query_stats.under_process)
                                    )
                                  ]
                                )
                              ])
                            ]),
                            _vm._v(" "),
                            _c("tr", [
                              _c("td", [
                                _vm._v(
                                  _vm._s(
                                    _vm.trans("query.awaiting_response") +
                                      " " +
                                      _vm.trans("query.query")
                                  )
                                )
                              ]),
                              _vm._v(" "),
                              _c("td", { staticClass: "text-right" }, [
                                _c(
                                  "span",
                                  { staticClass: "label label-info" },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.query_stats.awaiting_response)
                                    )
                                  ]
                                )
                              ])
                            ]),
                            _vm._v(" "),
                            _c("tr", [
                              _c("td", [
                                _vm._v(
                                  _vm._s(
                                    _vm.trans("query.resolved") +
                                      " " +
                                      _vm.trans("query.query")
                                  )
                                )
                              ]),
                              _vm._v(" "),
                              _c("td", { staticClass: "text-right" }, [
                                _c(
                                  "span",
                                  { staticClass: "label label-success" },
                                  [_vm._v(_vm._s(_vm.query_stats.resolved))]
                                )
                              ])
                            ])
                          ]
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.hasPermission("list-subscriber")
                        ? [
                            _c("tr", [
                              _c("td", [
                                _vm._v(
                                  _vm._s(
                                    _vm.trans("subscriber.subscribed") +
                                      " " +
                                      _vm.trans("user.user")
                                  )
                                )
                              ]),
                              _vm._v(" "),
                              _c("td", { staticClass: "text-right" }, [
                                _c(
                                  "span",
                                  { staticClass: "label label-success" },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.subscriber_stats.subscribed)
                                    )
                                  ]
                                )
                              ])
                            ]),
                            _vm._v(" "),
                            _c("tr", [
                              _c("td", [
                                _vm._v(
                                  _vm._s(
                                    _vm.trans("subscriber.unsubscribed") +
                                      " " +
                                      _vm.trans("user.user")
                                  )
                                )
                              ]),
                              _vm._v(" "),
                              _c("td", { staticClass: "text-right" }, [
                                _c(
                                  "span",
                                  { staticClass: "label label-danger" },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.subscriber_stats.unsubscribed)
                                    )
                                  ]
                                )
                              ])
                            ])
                          ]
                        : _vm._e()
                    ],
                    2
                  )
                ])
              ])
            ])
          ])
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-6" }, [
        _c("div", { staticClass: "card" }, [
          _c("div", { staticClass: "card-body" }, [
            _c("h4", { staticClass: "card-title" }, [
              _vm._v(_vm._s(_vm.trans("activity.activity_log")))
            ]),
            _vm._v(" "),
            !_vm.activity_logs.length
              ? _c("h6", { staticClass: "card-subtitle" }, [
                  _vm._v(_vm._s(_vm.trans("general.no_result_found")))
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.activity_logs.length
              ? _c("div", { staticClass: "table-responsive" }, [
                  _c("table", { staticClass: "table" }, [
                    _c("thead", [
                      _c("tr", [
                        _vm.hasRole("admin")
                          ? _c("th", [_vm._v(_vm._s(_vm.trans("user.user")))])
                          : _vm._e(),
                        _vm._v(" "),
                        _c("th", [
                          _vm._v(_vm._s(_vm.trans("activity.activity")))
                        ]),
                        _vm._v(" "),
                        _c("th", { staticClass: "table-option" }, [
                          _vm._v(_vm._s(_vm.trans("activity.date_time")))
                        ])
                      ])
                    ]),
                    _vm._v(" "),
                    _c(
                      "tbody",
                      _vm._l(_vm.activity_logs, function(activity_log) {
                        return _c("tr", [
                          _vm.hasRole("admin")
                            ? _c("td", {
                                domProps: {
                                  textContent: _vm._s(
                                    activity_log.user.profile.first_name +
                                      " " +
                                      activity_log.user.profile.last_name
                                  )
                                }
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _c("td", [
                            _vm._v(
                              _vm._s(
                                _vm.trans("activity." + activity_log.activity, {
                                  activity: _vm.trans(
                                    activity_log.module +
                                      "." +
                                      activity_log.module
                                  )
                                })
                              )
                            )
                          ]),
                          _vm._v(" "),
                          _c("td", { staticClass: "table-option" }, [
                            _vm._v(
                              _vm._s(
                                _vm._f("momentDateTime")(
                                  activity_log.created_at
                                )
                              )
                            )
                          ])
                        ])
                      }),
                      0
                    )
                  ])
                ])
              : _vm._e()
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-6" }, [
        _c("div", { staticClass: "card" }, [
          _c("div", { staticClass: "card-body" }, [
            _c("h4", { staticClass: "card-title" }, [
              _vm._v(_vm._s(_vm.trans("todo.todo")))
            ]),
            _vm._v(" "),
            !_vm.todos.length
              ? _c("h6", { staticClass: "card-subtitle" }, [
                  _vm._v(_vm._s(_vm.trans("general.no_result_found")))
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.todos.length
              ? _c("div", { staticClass: "table-responsive" }, [
                  _c("table", { staticClass: "table" }, [
                    _c("thead", [
                      _c("tr", [
                        _c("th", [_vm._v(_vm._s(_vm.trans("todo.title")))]),
                        _vm._v(" "),
                        _c("th", [_vm._v(_vm._s(_vm.trans("todo.status")))]),
                        _vm._v(" "),
                        _c("th", { staticClass: "table-option" }, [
                          _vm._v(_vm._s(_vm.trans("todo.date")))
                        ])
                      ])
                    ]),
                    _vm._v(" "),
                    _c(
                      "tbody",
                      _vm._l(_vm.todos, function(todo) {
                        return _c("tr", [
                          _c("td", {
                            domProps: { textContent: _vm._s(todo.title) }
                          }),
                          _vm._v(" "),
                          _c("td", {
                            domProps: { innerHTML: _vm._s(_vm.getStatus(todo)) }
                          }),
                          _vm._v(" "),
                          _c("td", { staticClass: "table-option" }, [
                            _vm._v(_vm._s(_vm._f("moment")(todo.date)))
                          ])
                        ])
                      }),
                      0
                    )
                  ])
                ])
              : _vm._e()
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }