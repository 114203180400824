var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "row page-titles" }, [
      _c("div", { staticClass: "col-md-6 col-8 align-self-center" }, [
        _c("h3", { staticClass: "text-themecolor m-b-0 m-t-0" }, [
          _vm._v(_vm._s(_vm.trans("auth.change_password")))
        ]),
        _vm._v(" "),
        _c("ol", { staticClass: "breadcrumb" }, [
          _c(
            "li",
            { staticClass: "breadcrumb-item" },
            [
              _c("router-link", { attrs: { to: "/home" } }, [
                _vm._v(_vm._s(_vm.trans("general.home")))
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c("li", { staticClass: "breadcrumb-item active" }, [
            _vm._v(_vm._s(_vm.trans("auth.change_password")))
          ])
        ])
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12" }, [
        _c("div", { staticClass: "card" }, [
          _c("div", { staticClass: "card-body" }, [
            _c(
              "form",
              {
                on: {
                  submit: function($event) {
                    $event.preventDefault()
                    return _vm.changePassword.apply(null, arguments)
                  },
                  keydown: function($event) {
                    return _vm.passwordForm.errors.clear($event.target.name)
                  }
                }
              },
              [
                _c("h4", { staticClass: "card-title" }, [
                  _vm._v(_vm._s(_vm.trans("auth.change_password")))
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-12 col-md-4" }, [
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v(_vm._s(_vm.trans("auth.current_password")))
                        ]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.passwordForm.current_password,
                              expression: "passwordForm.current_password"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: {
                            type: "password",
                            value: "",
                            name: "current_password",
                            placeholder: _vm.trans("auth.current_password")
                          },
                          domProps: {
                            value: _vm.passwordForm.current_password
                          },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.passwordForm,
                                "current_password",
                                $event.target.value
                              )
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c("show-error", {
                          attrs: {
                            "form-name": _vm.passwordForm,
                            "prop-name": "current_password"
                          }
                        })
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-12 col-md-4" }, [
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v(_vm._s(_vm.trans("auth.new_password")))
                        ]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.passwordForm.new_password,
                              expression: "passwordForm.new_password"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: {
                            type: "password",
                            name: "new_password",
                            placeholder: _vm.trans("auth.new_password")
                          },
                          domProps: { value: _vm.passwordForm.new_password },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.passwordForm,
                                "new_password",
                                $event.target.value
                              )
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c("show-error", {
                          attrs: {
                            "form-name": _vm.passwordForm,
                            "prop-name": "new_password"
                          }
                        })
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-12 col-md-4" }, [
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v(
                            _vm._s(_vm.trans("auth.new_password_confirmation"))
                          )
                        ]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.passwordForm.new_password_confirmation,
                              expression:
                                "passwordForm.new_password_confirmation"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: {
                            type: "password",
                            value: "",
                            name: "new_password_confirmation",
                            placeholder: _vm.trans(
                              "auth.new_password_confirmation"
                            )
                          },
                          domProps: {
                            value: _vm.passwordForm.new_password_confirmation
                          },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.passwordForm,
                                "new_password_confirmation",
                                $event.target.value
                              )
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c("show-error", {
                          attrs: {
                            "form-name": _vm.passwordForm,
                            "prop-name": "new_password_confirmation"
                          }
                        })
                      ],
                      1
                    )
                  ])
                ]),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-info waves-effect waves-light m-t-10",
                    attrs: { type: "submit" }
                  },
                  [_vm._v(_vm._s(_vm.trans("auth.change_password")))]
                )
              ]
            )
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }