var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "row page-titles" }, [
      _c("div", { staticClass: "col-md-6 col-8 align-self-center" }, [
        _c("h3", { staticClass: "text-themecolor m-b-0 m-t-0" }, [
          _vm._v(_vm._s(_vm.trans("template.template")))
        ]),
        _vm._v(" "),
        _c("ol", { staticClass: "breadcrumb" }, [
          _c(
            "li",
            { staticClass: "breadcrumb-item" },
            [
              _c("router-link", { attrs: { to: "/home" } }, [
                _vm._v(_vm._s(_vm.trans("general.home")))
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c("li", { staticClass: "breadcrumb-item active" }, [
            _vm._v(_vm._s(_vm.trans("template.template")))
          ])
        ])
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-12" },
        [
          _c("transition", { attrs: { name: "fade" } }, [
            _vm.showCreatePanel
              ? _c("div", { staticClass: "card" }, [
                  _c("div", { staticClass: "card-body" }, [
                    _vm.showCreatePanel
                      ? _c(
                          "button",
                          {
                            staticClass: "btn btn-info btn-sm pull-right",
                            on: {
                              click: function($event) {
                                _vm.showCreatePanel = !_vm.showCreatePanel
                              }
                            }
                          },
                          [_vm._v(_vm._s(_vm.trans("general.hide")))]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c("h4", { staticClass: "card-title" }, [
                      _vm._v(_vm._s(_vm.trans("template.add_new_template")))
                    ]),
                    _vm._v(" "),
                    _c(
                      "form",
                      {
                        on: {
                          submit: function($event) {
                            $event.preventDefault()
                            return _vm.submit.apply(null, arguments)
                          },
                          keydown: function($event) {
                            return _vm.templateForm.errors.clear(
                              $event.target.name
                            )
                          }
                        }
                      },
                      [
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col-12 col-md-6" }, [
                            _c(
                              "div",
                              { staticClass: "form-group" },
                              [
                                _c("label", { attrs: { for: "" } }, [
                                  _vm._v(_vm._s(_vm.trans("template.name")))
                                ]),
                                _vm._v(" "),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.templateForm.name,
                                      expression: "templateForm.name"
                                    }
                                  ],
                                  staticClass: "form-control",
                                  attrs: {
                                    type: "text",
                                    value: "",
                                    name: "name",
                                    placeholder: _vm.trans("template.name")
                                  },
                                  domProps: { value: _vm.templateForm.name },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.templateForm,
                                        "name",
                                        $event.target.value
                                      )
                                    }
                                  }
                                }),
                                _vm._v(" "),
                                _c("show-error", {
                                  attrs: {
                                    "form-name": _vm.templateForm,
                                    "prop-name": "name"
                                  }
                                })
                              ],
                              1
                            )
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-12 col-md-6" }, [
                            _c(
                              "div",
                              { staticClass: "form-group" },
                              [
                                _c("label", { attrs: { for: "" } }, [
                                  _vm._v(_vm._s(_vm.trans("template.category")))
                                ]),
                                _vm._v(" "),
                                _c("v-select", {
                                  attrs: {
                                    label: "name",
                                    name: "category",
                                    id: "category",
                                    options: _vm.categories,
                                    placeholder: _vm.trans(
                                      "template.select_category"
                                    )
                                  },
                                  on: {
                                    select: _vm.onCategorySelect,
                                    close: function($event) {
                                      return _vm.templateForm.errors.clear(
                                        "category"
                                      )
                                    },
                                    remove: function($event) {
                                      _vm.templateForm.category = ""
                                    }
                                  },
                                  model: {
                                    value: _vm.selected_category,
                                    callback: function($$v) {
                                      _vm.selected_category = $$v
                                    },
                                    expression: "selected_category"
                                  }
                                }),
                                _vm._v(" "),
                                _c("show-error", {
                                  attrs: {
                                    "form-name": _vm.templateForm,
                                    "prop-name": "category"
                                  }
                                })
                              ],
                              1
                            )
                          ])
                        ]),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass:
                              "btn btn-info waves-effect waves-light pull-right",
                            attrs: { type: "submit" }
                          },
                          [_vm._v(_vm._s(_vm.trans("general.save")))]
                        )
                      ]
                    )
                  ])
                ])
              : _vm._e()
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "card" }, [
            _c(
              "div",
              { staticClass: "card-body" },
              [
                _vm.email_templates.total && !_vm.showCreatePanel
                  ? _c(
                      "button",
                      {
                        staticClass: "btn btn-info btn-sm pull-right",
                        on: {
                          click: function($event) {
                            _vm.showCreatePanel = !_vm.showCreatePanel
                          }
                        }
                      },
                      [_vm._v(_vm._s(_vm.trans("template.add_new_template")))]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c("h4", { staticClass: "card-title" }, [
                  _vm._v(_vm._s(_vm.trans("template.template_list")))
                ]),
                _vm._v(" "),
                _vm.email_templates
                  ? _c("h6", { staticClass: "card-subtitle" }, [
                      _vm._v(
                        _vm._s(
                          _vm.trans("general.total_result_found", {
                            count: _vm.email_templates.total
                          })
                        )
                      )
                    ])
                  : _c("h6", { staticClass: "card-subtitle" }, [
                      _vm._v(_vm._s(_vm.trans("general.no_result_found")))
                    ]),
                _vm._v(" "),
                _vm.email_templates.total
                  ? _c("div", { staticClass: "table-responsive" }, [
                      _c("table", { staticClass: "table" }, [
                        _c("thead", [
                          _c("tr", [
                            _c("th", [
                              _vm._v(_vm._s(_vm.trans("template.name")))
                            ]),
                            _vm._v(" "),
                            _c("th", [
                              _vm._v(_vm._s(_vm.trans("template.category")))
                            ]),
                            _vm._v(" "),
                            _c("th", [
                              _vm._v(_vm._s(_vm.trans("template.subject")))
                            ]),
                            _vm._v(" "),
                            _c("th", { staticClass: "table-option" }, [
                              _vm._v(_vm._s(_vm.trans("general.action")))
                            ])
                          ])
                        ]),
                        _vm._v(" "),
                        _c(
                          "tbody",
                          _vm._l(_vm.email_templates.data, function(
                            email_template
                          ) {
                            return _c("tr", [
                              _c("td", {
                                domProps: {
                                  textContent: _vm._s(email_template.name)
                                }
                              }),
                              _vm._v(" "),
                              _c("td", {
                                domProps: {
                                  textContent: _vm._s(
                                    _vm.toWord(email_template.category)
                                  )
                                }
                              }),
                              _vm._v(" "),
                              _c("td", {
                                domProps: {
                                  textContent: _vm._s(email_template.subject)
                                }
                              }),
                              _vm._v(" "),
                              _c("td", { staticClass: "table-option" }, [
                                _c("div", { staticClass: "btn-group" }, [
                                  _c(
                                    "button",
                                    {
                                      directives: [
                                        {
                                          name: "tooltip",
                                          rawName: "v-tooltip",
                                          value: _vm.trans(
                                            "template.edit_template"
                                          ),
                                          expression:
                                            "trans('template.edit_template')"
                                        }
                                      ],
                                      staticClass: "btn btn-info btn-sm",
                                      on: {
                                        click: function($event) {
                                          $event.preventDefault()
                                          return _vm.editEmailTemplate(
                                            email_template
                                          )
                                        }
                                      }
                                    },
                                    [_c("i", { staticClass: "fas fa-edit" })]
                                  ),
                                  _vm._v(" "),
                                  !email_template.is_default
                                    ? _c(
                                        "button",
                                        {
                                          directives: [
                                            {
                                              name: "confirm",
                                              rawName: "v-confirm",
                                              value: {
                                                ok: _vm.confirmDelete(
                                                  email_template
                                                )
                                              },
                                              expression:
                                                "{ok: confirmDelete(email_template)}"
                                            },
                                            {
                                              name: "tooltip",
                                              rawName: "v-tooltip",
                                              value: _vm.trans(
                                                "template.delete_template"
                                              ),
                                              expression:
                                                "trans('template.delete_template')"
                                            }
                                          ],
                                          key: email_template.id,
                                          staticClass: "btn btn-danger btn-sm"
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "fas fa-trash"
                                          })
                                        ]
                                      )
                                    : _vm._e()
                                ])
                              ])
                            ])
                          }),
                          0
                        )
                      ])
                    ])
                  : _vm._e(),
                _vm._v(" "),
                !_vm.email_templates.total
                  ? _c(
                      "module-info",
                      {
                        attrs: {
                          module: "template",
                          title: "module_info_title",
                          description: "module_info_description",
                          icon: "envelope"
                        }
                      },
                      [
                        _c("div", { attrs: { slot: "btn" }, slot: "btn" }, [
                          !_vm.showCreatePanel
                            ? _c(
                                "button",
                                {
                                  staticClass: "btn btn-info btn-md",
                                  on: {
                                    click: function($event) {
                                      _vm.showCreatePanel = !_vm.showCreatePanel
                                    }
                                  }
                                },
                                [
                                  _c("i", { staticClass: "fas fa-plus" }),
                                  _vm._v(
                                    " " + _vm._s(_vm.trans("general.add_new"))
                                  )
                                ]
                              )
                            : _vm._e()
                        ])
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c("pagination-record", {
                  attrs: {
                    "page-length": _vm.filterEmailTemplateForm.page_length,
                    records: _vm.email_templates
                  },
                  on: {
                    "update:pageLength": function($event) {
                      return _vm.$set(
                        _vm.filterEmailTemplateForm,
                        "page_length",
                        $event
                      )
                    },
                    "update:page-length": function($event) {
                      return _vm.$set(
                        _vm.filterEmailTemplateForm,
                        "page_length",
                        $event
                      )
                    },
                    updateRecords: _vm.getEmailTemplates
                  },
                  nativeOn: {
                    change: function($event) {
                      return _vm.getEmailTemplates.apply(null, arguments)
                    }
                  }
                })
              ],
              1
            )
          ])
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }