var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "row page-titles" }, [
      _c("div", { staticClass: "col-md-6 col-8 align-self-center" }, [
        _c("h3", { staticClass: "text-themecolor m-b-0 m-t-0" }, [
          _vm._v(_vm._s(_vm.trans("configuration.configuration")))
        ]),
        _vm._v(" "),
        _c("ol", { staticClass: "breadcrumb" }, [
          _c(
            "li",
            { staticClass: "breadcrumb-item" },
            [
              _c("router-link", { attrs: { to: "/home" } }, [
                _vm._v(_vm._s(_vm.trans("general.home")))
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            { staticClass: "breadcrumb-item" },
            [
              _c("router-link", { attrs: { to: "/configuration/basic" } }, [
                _vm._v(_vm._s(_vm.trans("configuration.configuration")))
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c("li", { staticClass: "breadcrumb-item active" }, [
            _vm._v(_vm._s(_vm.trans("auth.authentication")))
          ])
        ])
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12" }, [
        _c("div", { staticClass: "card" }, [
          _c("div", { staticClass: "card-body" }, [
            _c(
              "div",
              { staticClass: "row" },
              [
                _c("configuration-sidebar", {
                  attrs: { menu: "authentication" }
                }),
                _vm._v(" "),
                _c("div", { staticClass: "col-10 col-lg-10 col-md-10" }, [
                  _c("h4", { staticClass: "card-title" }, [
                    _vm._v(_vm._s(_vm.trans("auth.authentication")))
                  ]),
                  _vm._v(" "),
                  _c(
                    "form",
                    {
                      on: {
                        submit: function($event) {
                          $event.preventDefault()
                          return _vm.submit.apply(null, arguments)
                        },
                        keydown: function($event) {
                          return _vm.configForm.errors.clear($event.target.name)
                        }
                      }
                    },
                    [
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-12 col-md-6" }, [
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col-12 col-md-6" }, [
                              _c(
                                "div",
                                { staticClass: "form-group" },
                                [
                                  _c(
                                    "label",
                                    { attrs: { for: "" } },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.trans("auth.token_lifetime") +
                                            " " +
                                            _vm.trans("auth.in_minute")
                                        ) + " "
                                      ),
                                      _c("show-tip", {
                                        attrs: {
                                          module: "auth",
                                          tip: "tip_token_lifetime",
                                          type: "field"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.configForm.token_lifetime,
                                        expression: "configForm.token_lifetime"
                                      }
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      type: "number",
                                      value: "",
                                      name: "token_lifetime",
                                      placeholder: _vm.trans(
                                        "auth.token_lifetime"
                                      )
                                    },
                                    domProps: {
                                      value: _vm.configForm.token_lifetime
                                    },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.configForm,
                                          "token_lifetime",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("show-error", {
                                    attrs: {
                                      "form-name": _vm.configForm,
                                      "prop-name": "token_lifetime"
                                    }
                                  })
                                ],
                                1
                              )
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "col-12 col-md-6" }, [
                              _c(
                                "div",
                                { staticClass: "form-group" },
                                [
                                  _c(
                                    "label",
                                    { attrs: { for: "" } },
                                    [
                                      _c("small", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.trans(
                                              "auth.reset_password_token_lifetime"
                                            ) +
                                              " " +
                                              _vm.trans("auth.in_minute")
                                          )
                                        )
                                      ]),
                                      _vm._v(" "),
                                      _c("show-tip", {
                                        attrs: {
                                          module: "auth",
                                          tip:
                                            "tip_reset_password_token_lifetime",
                                          type: "field"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value:
                                          _vm.configForm
                                            .reset_password_token_lifetime,
                                        expression:
                                          "configForm.reset_password_token_lifetime"
                                      }
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      type: "number",
                                      value: "",
                                      name: "reset_password_token_lifetime",
                                      placeholder: _vm.trans(
                                        "auth.reset_password_token_lifetime"
                                      )
                                    },
                                    domProps: {
                                      value:
                                        _vm.configForm
                                          .reset_password_token_lifetime
                                    },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.configForm,
                                          "reset_password_token_lifetime",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("show-error", {
                                    attrs: {
                                      "form-name": _vm.configForm,
                                      "prop-name":
                                        "reset_password_token_lifetime"
                                    }
                                  })
                                ],
                                1
                              )
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "col-12 col-md-6" }, [
                              _c("div", { staticClass: "form-group" }, [
                                _c(
                                  "label",
                                  { attrs: { for: "" } },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.trans("auth.reset_password")) +
                                        " "
                                    ),
                                    _c("show-tip", {
                                      attrs: {
                                        module: "auth",
                                        tip: "tip_reset_password",
                                        type: "field"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  [
                                    _c("switches", {
                                      attrs: {
                                        theme: "bootstrap",
                                        color: "success"
                                      },
                                      model: {
                                        value: _vm.configForm.reset_password,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.configForm,
                                            "reset_password",
                                            $$v
                                          )
                                        },
                                        expression: "configForm.reset_password"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ])
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "col-12 col-md-6" }, [
                              _c("div", { staticClass: "form-group" }, [
                                _c(
                                  "label",
                                  { attrs: { for: "" } },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.trans("auth.two_factor_security")
                                      ) + " "
                                    ),
                                    _c("show-tip", {
                                      attrs: {
                                        module: "auth",
                                        tip: "tip_two_factor_security",
                                        type: "field"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  [
                                    _c("switches", {
                                      attrs: {
                                        theme: "bootstrap",
                                        color: "success"
                                      },
                                      model: {
                                        value:
                                          _vm.configForm.two_factor_security,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.configForm,
                                            "two_factor_security",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "configForm.two_factor_security"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ])
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "col-12 col-md-6" })
                          ])
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-12 col-md-6" }, [
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col-12 col-md-6" }, [
                              _c("div", { staticClass: "form-group" }, [
                                _c(
                                  "label",
                                  { attrs: { for: "" } },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.trans("auth.lock_screen")) +
                                        " "
                                    ),
                                    _c("show-tip", {
                                      attrs: {
                                        module: "auth",
                                        tip: "tip_lock_screen",
                                        type: "field"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  [
                                    _c("switches", {
                                      attrs: {
                                        theme: "bootstrap",
                                        color: "success"
                                      },
                                      model: {
                                        value: _vm.configForm.lock_screen,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.configForm,
                                            "lock_screen",
                                            $$v
                                          )
                                        },
                                        expression: "configForm.lock_screen"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ])
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "col-12 col-md-6" }, [
                              _vm.configForm.lock_screen
                                ? _c(
                                    "div",
                                    { staticClass: "form-group" },
                                    [
                                      _c(
                                        "label",
                                        { attrs: { for: "" } },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.trans(
                                                "auth.lock_screen_timeout"
                                              ) +
                                                " " +
                                                _vm.trans("auth.in_minute")
                                            ) + " "
                                          ),
                                          _c("show-tip", {
                                            attrs: {
                                              module: "auth",
                                              tip: "tip_lock_screen_timeout",
                                              type: "field"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value:
                                              _vm.configForm
                                                .lock_screen_timeout,
                                            expression:
                                              "configForm.lock_screen_timeout"
                                          }
                                        ],
                                        staticClass: "form-control",
                                        attrs: {
                                          type: "number",
                                          value: "",
                                          name: "lock_screen_timeout",
                                          placeholder: _vm.trans(
                                            "auth.lock_screen_timeout"
                                          )
                                        },
                                        domProps: {
                                          value:
                                            _vm.configForm.lock_screen_timeout
                                        },
                                        on: {
                                          input: function($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.$set(
                                              _vm.configForm,
                                              "lock_screen_timeout",
                                              $event.target.value
                                            )
                                          }
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("show-error", {
                                        attrs: {
                                          "form-name": _vm.configForm,
                                          "prop-name": "lock_screen_timeout"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "col-12 col-md-6" }, [
                              _c("div", { staticClass: "form-group" }, [
                                _c(
                                  "label",
                                  { attrs: { for: "" } },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.trans("auth.login_throttle")) +
                                        " "
                                    ),
                                    _c("show-tip", {
                                      attrs: {
                                        module: "auth",
                                        tip: "tip_login_throttle",
                                        type: "field"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  [
                                    _c("switches", {
                                      attrs: {
                                        theme: "bootstrap",
                                        color: "success"
                                      },
                                      model: {
                                        value: _vm.configForm.login_throttle,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.configForm,
                                            "login_throttle",
                                            $$v
                                          )
                                        },
                                        expression: "configForm.login_throttle"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ])
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "col-12 col-md-6" })
                          ]),
                          _vm._v(" "),
                          _vm.configForm.login_throttle
                            ? _c("div", [
                                _c("div", { staticClass: "row" }, [
                                  _c(
                                    "div",
                                    { staticClass: "col-12 col-md-6" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "form-group" },
                                        [
                                          _c(
                                            "label",
                                            { attrs: { for: "" } },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.trans(
                                                    "auth.login_throttle_attempt"
                                                  )
                                                ) + " "
                                              ),
                                              _c("show-tip", {
                                                attrs: {
                                                  module: "auth",
                                                  tip:
                                                    "tip_login_throttle_attempt",
                                                  type: "field"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.configForm
                                                    .login_throttle_attempt,
                                                expression:
                                                  "configForm.login_throttle_attempt"
                                              }
                                            ],
                                            staticClass: "form-control",
                                            attrs: {
                                              type: "number",
                                              value: "",
                                              name: "login_throttle_attempt",
                                              placeholder: _vm.trans(
                                                "auth.login_throttle_attempt"
                                              )
                                            },
                                            domProps: {
                                              value:
                                                _vm.configForm
                                                  .login_throttle_attempt
                                            },
                                            on: {
                                              input: function($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.$set(
                                                  _vm.configForm,
                                                  "login_throttle_attempt",
                                                  $event.target.value
                                                )
                                              }
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c("show-error", {
                                            attrs: {
                                              "form-name": _vm.configForm,
                                              "prop-name":
                                                "login_throttle_attempt"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "col-12 col-md-6" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "form-group" },
                                        [
                                          _c(
                                            "label",
                                            { attrs: { for: "" } },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.trans(
                                                    "auth.login_throttle_timeout"
                                                  ) +
                                                    " " +
                                                    _vm.trans("auth.in_minute")
                                                ) + " "
                                              ),
                                              _c("show-tip", {
                                                attrs: {
                                                  module: "auth",
                                                  tip:
                                                    "tip_login_throttle_timeout",
                                                  type: "field"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.configForm
                                                    .login_throttle_timeout,
                                                expression:
                                                  "configForm.login_throttle_timeout"
                                              }
                                            ],
                                            staticClass: "form-control",
                                            attrs: {
                                              type: "number",
                                              value: "",
                                              name: "login_throttle_timeout",
                                              placeholder: _vm.trans(
                                                "auth.login_throttle_timeout"
                                              )
                                            },
                                            domProps: {
                                              value:
                                                _vm.configForm
                                                  .login_throttle_timeout
                                            },
                                            on: {
                                              input: function($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.$set(
                                                  _vm.configForm,
                                                  "login_throttle_timeout",
                                                  $event.target.value
                                                )
                                              }
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c("show-error", {
                                            attrs: {
                                              "form-name": _vm.configForm,
                                              "prop-name":
                                                "login_throttle_timeout"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  )
                                ])
                              ])
                            : _vm._e()
                        ])
                      ]),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass:
                            "btn btn-info waves-effect waves-light m-t-10 pull-right",
                          attrs: { type: "submit" }
                        },
                        [_vm._v(_vm._s(_vm.trans("general.save")))]
                      )
                    ]
                  )
                ])
              ],
              1
            )
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }