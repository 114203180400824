var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "row page-titles" }, [
      _c("div", { staticClass: "col-md-6 col-8 align-self-center" }, [
        _c("h3", { staticClass: "text-themecolor m-b-0 m-t-0" }, [
          _vm._v(_vm._s(_vm.trans("instance.instance")))
        ]),
        _vm._v(" "),
        _c("ol", { staticClass: "breadcrumb" }, [
          _c(
            "li",
            { staticClass: "breadcrumb-item" },
            [
              _c("router-link", { attrs: { to: "/home" } }, [
                _vm._v(_vm._s(_vm.trans("general.home")))
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c("li", { staticClass: "breadcrumb-item active" }, [
            _vm._v(_vm._s(_vm.trans("instance.instance")))
          ])
        ])
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12 col-sm-4" }, [
        _c("div", { staticClass: "card" }, [
          _c("div", { staticClass: "card-body" }, [
            _c("h4", { staticClass: "card-title" }, [
              _vm._v(_vm._s(_vm.trans("instance.instance_detail")))
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "d-flex align-items-center flex-row m-t-10" },
              [
                _c("div", { staticClass: "p-2 display-5 text-info" }, [
                  _vm.instance.status === "pending"
                    ? _c("span", [
                        _c("i", {
                          staticClass: "fas fa-exclamation-triangle text-info"
                        })
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.instance.status === "running"
                    ? _c("span", [
                        _c("i", {
                          staticClass: "fas fa-check-circle text-success"
                        })
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.instance.status === "expired"
                    ? _c("span", [
                        _c("i", { staticClass: "fas fa-lock text-danger" })
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.instance.status === "suspended"
                    ? _c("span", [
                        _c("i", { staticClass: "fas fa-ban text-warning" })
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.instance.status === "terminated"
                    ? _c("span", [
                        _c("i", {
                          staticClass: "fas fa-times-circle text-danger"
                        })
                      ])
                    : _vm._e()
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "p-2" }, [
                  _c("h3", { staticClass: "m-b-0" }, [
                    _vm._v(
                      "\n                                " +
                        _vm._s(_vm.trans("instance." + _vm.instance.status)) +
                        "\n\n                                "
                    ),
                    _vm.instance.is_trial
                      ? _c(
                          "span",
                          { staticClass: "badge badge-danger text-right" },
                          [_vm._v(_vm._s(_vm.trans("subscription.trial")))]
                        )
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _vm.instance.status != "pending"
                    ? _c("small", [
                        _vm._v(
                          _vm._s(_vm.trans("subscription.till")) +
                            " " +
                            _vm._s(
                              _vm._f("moment")(_vm.instance.date_of_expiry)
                            )
                        )
                      ])
                    : _vm._e()
                ])
              ]
            ),
            _vm._v(" "),
            _c("table", { staticClass: "table no-border" }, [
              _c("tr", [
                _c("td", [_vm._v(_vm._s(_vm.trans("instance.email")))]),
                _vm._v(" "),
                _c("td", { staticClass: "text-right" }, [
                  _vm._v(_vm._s(_vm.instance.email))
                ])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [_vm._v(_vm._s(_vm.trans("instance.domain")))]),
                _vm._v(" "),
                _c("td", { staticClass: "text-right" }, [
                  _vm._v(_vm._s(_vm.instance.domain))
                ])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [_vm._v(_vm._s(_vm.trans("instance.custom_domain")))]),
                _vm._v(" "),
                _c("td", { staticClass: "text-right" }, [
                  _vm._v(_vm._s(_vm.instance.custom_domain))
                ])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [_vm._v(_vm._s(_vm.trans("user.name")))]),
                _vm._v(" "),
                _c("td", { staticClass: "text-right" }, [
                  _vm._v(
                    _vm._s(
                      _vm.instance.first_name + " " + _vm.instance.last_name
                    )
                  )
                ])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [_vm._v("Phone")]),
                _vm._v(" "),
                _c("td", { staticClass: "text-right" }, [
                  _vm._v(_vm._s(_vm.instance.phone))
                ])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [_vm._v(_vm._s(_vm.trans("plan.plan")))]),
                _vm._v(" "),
                _c("td", { staticClass: "text-right" }, [
                  _vm._v(
                    _vm._s(_vm.instance.plan.name) + "  " + _vm._s(_vm.getPrice)
                  )
                ])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [_vm._v(_vm._s(_vm.trans("instance.created_at")))]),
                _vm._v(" "),
                _c("td", { staticClass: "text-right" }, [
                  _vm._v(
                    _vm._s(_vm._f("momentDateTime")(_vm.instance.created_at))
                  )
                ])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [_vm._v(_vm._s(_vm.trans("instance.updated_at")))]),
                _vm._v(" "),
                _c("td", { staticClass: "text-right" }, [
                  _vm._v(
                    _vm._s(_vm._f("momentDateTime")(_vm.instance.updated_at))
                  )
                ])
              ])
            ])
          ])
        ]),
        _vm._v(" "),
        _vm.instance.status != "pending" && _vm.hasPermission("edit-instance")
          ? _c("div", { staticClass: "card" }, [
              _c("div", { staticClass: "card-body" }, [
                _c("h4", { staticClass: "card-title" }, [
                  _vm._v(_vm._s(_vm.trans("instance.update")))
                ]),
                _vm._v(" "),
                _c(
                  "form",
                  {
                    on: {
                      submit: function($event) {
                        $event.preventDefault()
                        return _vm.update.apply(null, arguments)
                      },
                      keydown: function($event) {
                        return _vm.updateInstanceForm.errors.clear(
                          $event.target.name
                        )
                      }
                    }
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v(_vm._s(_vm.trans("instance.status")))
                        ]),
                        _vm._v(" "),
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.updateInstanceForm.status,
                                expression: "updateInstanceForm.status"
                              }
                            ],
                            staticClass: "form-control",
                            attrs: { name: "status" },
                            on: {
                              change: function($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function(o) {
                                    return o.selected
                                  })
                                  .map(function(o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  _vm.updateInstanceForm,
                                  "status",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              }
                            }
                          },
                          [
                            _c("option", { attrs: { value: "running" } }, [
                              _vm._v(_vm._s(_vm.trans("instance.running")))
                            ]),
                            _vm._v(" "),
                            _c("option", { attrs: { value: "expired" } }, [
                              _vm._v(_vm._s(_vm.trans("instance.expired")))
                            ]),
                            _vm._v(" "),
                            _c("option", { attrs: { value: "suspended" } }, [
                              _vm._v(_vm._s(_vm.trans("instance.suspended")))
                            ]),
                            _vm._v(" "),
                            _c("option", { attrs: { value: "terminated" } }, [
                              _vm._v(_vm._s(_vm.trans("instance.terminated")))
                            ])
                          ]
                        ),
                        _vm._v(" "),
                        _c("show-error", {
                          attrs: {
                            "form-name": _vm.updateInstanceForm,
                            "prop-name": "status"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v(_vm._s(_vm.trans("subscription.validity")))
                        ]),
                        _vm._v(" "),
                        _c("datepicker", {
                          attrs: {
                            bootstrapStyling: true,
                            name: "date_of_expiry"
                          },
                          on: {
                            selected: function($event) {
                              return _vm.updateInstanceForm.errors.clear(
                                "date_of_expiry"
                              )
                            }
                          },
                          model: {
                            value: _vm.updateInstanceForm.date_of_expiry,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.updateInstanceForm,
                                "date_of_expiry",
                                $$v
                              )
                            },
                            expression: "updateInstanceForm.date_of_expiry"
                          }
                        }),
                        _vm._v(" "),
                        _c("show-error", {
                          attrs: {
                            "form-name": _vm.updateInstanceForm,
                            "prop-name": "date_of_expiry"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v(_vm._s(_vm.trans("instance.custom_domain")))
                        ]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.updateInstanceForm.custom_domain,
                              expression: "updateInstanceForm.custom_domain"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: { type: "text", name: "custom_domain" },
                          domProps: {
                            value: _vm.updateInstanceForm.custom_domain
                          },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.updateInstanceForm,
                                "custom_domain",
                                $event.target.value
                              )
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c("show-error", {
                          attrs: {
                            "form-name": _vm.updateInstanceForm,
                            "prop-name": "custom_domain"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v(_vm._s(_vm.trans("instance.max_student")))
                        ]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.updateInstanceForm.max_student,
                              expression: "updateInstanceForm.max_student"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: { type: "text", name: "max_student" },
                          domProps: {
                            value: _vm.updateInstanceForm.max_student
                          },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.updateInstanceForm,
                                "max_student",
                                $event.target.value
                              )
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c("show-error", {
                          attrs: {
                            "form-name": _vm.updateInstanceForm,
                            "prop-name": "max_student"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v(_vm._s(_vm.trans("instance.remarks")))
                        ]),
                        _vm._v(" "),
                        _c("autosize-textarea", {
                          attrs: {
                            rows: "2",
                            name: "remarks",
                            placeholder: _vm.trans("instance.remarks")
                          },
                          model: {
                            value: _vm.updateInstanceForm.remarks,
                            callback: function($$v) {
                              _vm.$set(_vm.updateInstanceForm, "remarks", $$v)
                            },
                            expression: "updateInstanceForm.remarks"
                          }
                        }),
                        _vm._v(" "),
                        _c("show-error", {
                          attrs: {
                            "form-name": _vm.updateInstanceForm,
                            "prop-name": "remarks"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", { attrs: { for: "" } }, [
                        _vm._v(
                          _vm._s(
                            _vm.trans(
                              "configuration.single_instance_maintenance_mode"
                            )
                          )
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        [
                          _c("switches", {
                            attrs: {
                              name: "maintenance_mode",
                              theme: "bootstrap",
                              color: "success"
                            },
                            model: {
                              value: _vm.updateInstanceForm.maintenance_mode,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.updateInstanceForm,
                                  "maintenance_mode",
                                  $$v
                                )
                              },
                              expression: "updateInstanceForm.maintenance_mode"
                            }
                          })
                        ],
                        1
                      )
                    ]),
                    _vm._v(" "),
                    _vm.updateInstanceForm.maintenance_mode
                      ? [
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c("label", { attrs: { for: "" } }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.trans(
                                      "configuration.instance_maintenance_mode_message"
                                    )
                                  )
                                )
                              ]),
                              _vm._v(" "),
                              _c("autosize-textarea", {
                                attrs: {
                                  rows: "2",
                                  name: "maintenance_mode_message",
                                  placeholder: _vm.trans(
                                    "configuration.instance_maintenance_mode_message"
                                  )
                                },
                                model: {
                                  value:
                                    _vm.updateInstanceForm
                                      .maintenance_mode_message,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.updateInstanceForm,
                                      "maintenance_mode_message",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "updateInstanceForm.maintenance_mode_message"
                                }
                              }),
                              _vm._v(" "),
                              _c("show-error", {
                                attrs: {
                                  "form-name": _vm.updateInstanceForm,
                                  "prop-name": "maintenance_mode_message"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c(
                                "label",
                                { attrs: { for: "" } },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.trans(
                                        "configuration.instance_maintenance_exclude_ip"
                                      )
                                    ) + " "
                                  ),
                                  _c("show-tip", {
                                    attrs: {
                                      module: "configuration",
                                      tip:
                                        "tip_instance_maintenance_exclude_ip",
                                      type: "field"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("autosize-textarea", {
                                attrs: {
                                  rows: "2",
                                  name: "exclude_ip_from_maintenance",
                                  placeholder: _vm.trans(
                                    "configuration.instance_maintenance_exclude_ip"
                                  )
                                },
                                model: {
                                  value:
                                    _vm.updateInstanceForm
                                      .exclude_ip_from_maintenance,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.updateInstanceForm,
                                      "exclude_ip_from_maintenance",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "updateInstanceForm.exclude_ip_from_maintenance"
                                }
                              }),
                              _vm._v(" "),
                              _c("show-error", {
                                attrs: {
                                  "form-name": _vm.updateInstanceForm,
                                  "prop-name": "exclude_ip_from_maintenance"
                                }
                              })
                            ],
                            1
                          )
                        ]
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass:
                          "btn btn-info waves-effect waves-light pull-right",
                        attrs: { type: "submit" }
                      },
                      [_vm._v(_vm._s(_vm.trans("general.save")))]
                    )
                  ],
                  2
                )
              ])
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.instance.status != "pending" && _vm.hasPermission("edit-instance")
          ? _c("div", { staticClass: "card" }, [
              _c("div", { staticClass: "card-body" }, [
                _c("h4", { staticClass: "card-title" }, [
                  _vm._v(_vm._s(_vm.trans("user.force_change_password")))
                ]),
                _vm._v(" "),
                _c(
                  "form",
                  {
                    on: {
                      submit: function($event) {
                        $event.preventDefault()
                        return _vm.updatePassword.apply(null, arguments)
                      },
                      keydown: function($event) {
                        return _vm.updatePasswordForm.errors.clear(
                          $event.target.name
                        )
                      }
                    }
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v(_vm._s(_vm.trans("auth.new_password")))
                        ]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.updatePasswordForm.new_password,
                              expression: "updatePasswordForm.new_password"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: { type: "password", name: "new_password" },
                          domProps: {
                            value: _vm.updatePasswordForm.new_password
                          },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.updatePasswordForm,
                                "new_password",
                                $event.target.value
                              )
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c("show-error", {
                          attrs: {
                            "form-name": _vm.updatePasswordForm,
                            "prop-name": "new_password"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v(
                            _vm._s(_vm.trans("auth.new_password_confirmation"))
                          )
                        ]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value:
                                _vm.updatePasswordForm
                                  .new_password_confirmation,
                              expression:
                                "updatePasswordForm.new_password_confirmation"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: {
                            type: "password",
                            name: "new_password_confirmation"
                          },
                          domProps: {
                            value:
                              _vm.updatePasswordForm.new_password_confirmation
                          },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.updatePasswordForm,
                                "new_password_confirmation",
                                $event.target.value
                              )
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c("show-error", {
                          attrs: {
                            "form-name": _vm.updatePasswordForm,
                            "prop-name": "new_password_confirmation"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass:
                          "btn btn-info waves-effect waves-light pull-right",
                        attrs: { type: "submit" }
                      },
                      [_vm._v(_vm._s(_vm.trans("general.save")))]
                    )
                  ]
                )
              ])
            ])
          : _vm._e()
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-12 col-sm-8" }, [
        _c("div", { staticClass: "card" }, [
          _c(
            "div",
            { staticClass: "card-body" },
            [
              _c("h4", { staticClass: "card-title" }, [
                _vm._v(_vm._s(_vm.trans("instance.instance_detail")))
              ]),
              _vm._v(" "),
              _vm.subscriptions
                ? _c("h6", { staticClass: "card-subtitle" }, [
                    _vm._v(
                      _vm._s(
                        _vm.trans("general.total_result_found", {
                          count: _vm.subscriptions.total
                        })
                      )
                    )
                  ])
                : _c("h6", { staticClass: "card-subtitle" }, [
                    _vm._v(_vm._s(_vm.trans("general.no_result_found")))
                  ]),
              _vm._v(" "),
              _vm.subscriptions.total
                ? _c("div", { staticClass: "table-responsive" }, [
                    _c("table", { staticClass: "table" }, [
                      _c("thead", [
                        _c("tr", [
                          _c("th", [
                            _vm._v(_vm._s(_vm.trans("subscription.date")))
                          ]),
                          _vm._v(" "),
                          _c("th", [
                            _vm._v(_vm._s(_vm.trans("subscription.amount")))
                          ]),
                          _vm._v(" "),
                          _c("th", [
                            _vm._v(_vm._s(_vm.trans("subscription.plan")))
                          ]),
                          _vm._v(" "),
                          _c("th", [
                            _vm._v(_vm._s(_vm.trans("subscription.method")))
                          ]),
                          _vm._v(" "),
                          _c("th", [
                            _vm._v(_vm._s(_vm.trans("subscription.validity")))
                          ]),
                          _vm._v(" "),
                          _c("th", [
                            _vm._v(_vm._s(_vm.trans("subscription.status")))
                          ])
                        ])
                      ]),
                      _vm._v(" "),
                      _c(
                        "tbody",
                        _vm._l(_vm.subscriptions.data, function(subscription) {
                          return _c("tr", [
                            _c("td", [
                              _vm._v(
                                _vm._s(
                                  _vm._f("moment")(subscription.created_at)
                                )
                              )
                            ]),
                            _vm._v(" "),
                            _c("td", {
                              domProps: {
                                textContent: _vm._s(
                                  _vm.formatCurrency(
                                    subscription.amount,
                                    subscription.currency
                                  )
                                )
                              }
                            }),
                            _vm._v(" "),
                            _c("td", {
                              domProps: {
                                textContent: _vm._s(subscription.plan.name)
                              }
                            }),
                            _vm._v(" "),
                            _c("td", {
                              domProps: {
                                textContent: _vm._s(
                                  subscription.method === "paypal"
                                    ? "Paypal"
                                    : "Credit Card"
                                )
                              }
                            }),
                            _vm._v(" "),
                            _c("td", [
                              _vm._v(
                                _vm._s(_vm._f("moment")(subscription.validity))
                              )
                            ]),
                            _vm._v(" "),
                            _c("td", [
                              subscription.status
                                ? _c(
                                    "span",
                                    { staticClass: "badge badge-success" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.trans("subscription.complete")
                                        )
                                      )
                                    ]
                                  )
                                : _c(
                                    "span",
                                    { staticClass: "badge badge-danger" },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.trans("subscription.failed"))
                                      )
                                    ]
                                  )
                            ])
                          ])
                        }),
                        0
                      )
                    ])
                  ])
                : _vm._e(),
              _vm._v(" "),
              !_vm.subscriptions.total
                ? _c("module-info", {
                    attrs: {
                      module: "subscription",
                      title: "module_info_title",
                      icon: "life-ring"
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("pagination-record", {
                attrs: {
                  "page-length": _vm.filterSubscriptionForm.page_length,
                  records: _vm.subscriptions
                },
                on: {
                  "update:pageLength": function($event) {
                    return _vm.$set(
                      _vm.filterSubscriptionForm,
                      "page_length",
                      $event
                    )
                  },
                  "update:page-length": function($event) {
                    return _vm.$set(
                      _vm.filterSubscriptionForm,
                      "page_length",
                      $event
                    )
                  },
                  updateRecords: _vm.getSubscriptions
                },
                nativeOn: {
                  change: function($event) {
                    return _vm.getSubscriptions.apply(null, arguments)
                  }
                }
              })
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "card" }, [
          _c("div", { staticClass: "card-body" }, [
            _c("h4", { staticClass: "card-title" }, [
              _vm._v(_vm._s(_vm.trans("subscription.add_new")))
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "alert alert-info" }, [
              _vm._v(_vm._s(_vm.trans("subscription.add_new_tip")))
            ]),
            _vm._v(" "),
            _c(
              "form",
              {
                on: {
                  submit: function($event) {
                    $event.preventDefault()
                    return _vm.addNewSubscription.apply(null, arguments)
                  },
                  keydown: function($event) {
                    return _vm.subscriptionForm.errors.clear($event.target.name)
                  }
                }
              },
              [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-12 col-sm-6" }, [
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v(_vm._s(_vm.trans("plan.plan")))
                        ]),
                        _vm._v(" "),
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.subscriptionForm.plan_id,
                                expression: "subscriptionForm.plan_id"
                              }
                            ],
                            staticClass: "form-control",
                            attrs: { name: "plan_id" },
                            on: {
                              change: function($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function(o) {
                                    return o.selected
                                  })
                                  .map(function(o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  _vm.subscriptionForm,
                                  "plan_id",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              }
                            }
                          },
                          [
                            _c("option", { attrs: { value: "" } }, [
                              _vm._v(_vm._s(_vm.trans("general.select_one")))
                            ]),
                            _vm._v(" "),
                            _vm._l(_vm.plans, function(plan) {
                              return _c(
                                "option",
                                { domProps: { value: plan.id } },
                                [_vm._v(_vm._s(plan.name))]
                              )
                            })
                          ],
                          2
                        ),
                        _vm._v(" "),
                        _c("show-error", {
                          attrs: {
                            "form-name": _vm.subscriptionForm,
                            "prop-name": "plan_id"
                          }
                        })
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-12 col-sm-6" }, [
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v(_vm._s(_vm.trans("plan.payment_frequency")))
                        ]),
                        _vm._v(" "),
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.subscriptionForm.frequency,
                                expression: "subscriptionForm.frequency"
                              }
                            ],
                            staticClass: "form-control",
                            attrs: { name: "frequency" },
                            on: {
                              change: function($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function(o) {
                                    return o.selected
                                  })
                                  .map(function(o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  _vm.subscriptionForm,
                                  "frequency",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              }
                            }
                          },
                          [
                            _c("option", { attrs: { value: "" } }, [
                              _vm._v(_vm._s(_vm.trans("general.select_one")))
                            ]),
                            _vm._v(" "),
                            _vm._l(_vm.payment_frequencies, function(
                              frequency
                            ) {
                              return _c(
                                "option",
                                { domProps: { value: frequency.id } },
                                [_vm._v(_vm._s(frequency.name))]
                              )
                            })
                          ],
                          2
                        ),
                        _vm._v(" "),
                        _c("show-error", {
                          attrs: {
                            "form-name": _vm.subscriptionForm,
                            "prop-name": "frequency"
                          }
                        })
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-12 col-sm-6" }, [
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v(_vm._s(_vm.trans("currency.currency")))
                        ]),
                        _vm._v(" "),
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.subscriptionForm.currency_id,
                                expression: "subscriptionForm.currency_id"
                              }
                            ],
                            staticClass: "form-control",
                            attrs: { name: "currency_id" },
                            on: {
                              change: function($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function(o) {
                                    return o.selected
                                  })
                                  .map(function(o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  _vm.subscriptionForm,
                                  "currency_id",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              }
                            }
                          },
                          [
                            _c("option", { attrs: { value: "" } }, [
                              _vm._v(_vm._s(_vm.trans("general.select_one")))
                            ]),
                            _vm._v(" "),
                            _vm._l(_vm.currencies, function(currency) {
                              return _c(
                                "option",
                                { domProps: { value: currency.id } },
                                [_vm._v(_vm._s(currency.name))]
                              )
                            })
                          ],
                          2
                        ),
                        _vm._v(" "),
                        _c("show-error", {
                          attrs: {
                            "form-name": _vm.subscriptionForm,
                            "prop-name": "currency_id"
                          }
                        })
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-12 col-sm-6" }, [
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v(_vm._s(_vm.trans("subscription.amount")))
                        ]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.subscriptionForm.amount,
                              expression: "subscriptionForm.amount"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: {
                            type: "text",
                            name: "amount",
                            placeholder: _vm.trans("subscription.amount")
                          },
                          domProps: { value: _vm.subscriptionForm.amount },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.subscriptionForm,
                                "amount",
                                $event.target.value
                              )
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c("show-error", {
                          attrs: {
                            "form-name": _vm.subscriptionForm,
                            "prop-name": "amount"
                          }
                        })
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-12 col-sm-6" }, [
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v(_vm._s(_vm.trans("subscription.validity")))
                        ]),
                        _vm._v(" "),
                        _c("datepicker", {
                          attrs: {
                            bootstrapStyling: true,
                            name: "date_of_expiry"
                          },
                          on: {
                            selected: function($event) {
                              return _vm.subscriptionForm.errors.clear(
                                "date_of_expiry"
                              )
                            }
                          },
                          model: {
                            value: _vm.subscriptionForm.date_of_expiry,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.subscriptionForm,
                                "date_of_expiry",
                                $$v
                              )
                            },
                            expression: "subscriptionForm.date_of_expiry"
                          }
                        }),
                        _vm._v(" "),
                        _c("show-error", {
                          attrs: {
                            "form-name": _vm.subscriptionForm,
                            "prop-name": "date_of_expiry"
                          }
                        })
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-12 col-sm-6" }, [
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v(
                            _vm._s(_vm.trans("subscription.payment_method"))
                          )
                        ]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.subscriptionForm.payment_method,
                              expression: "subscriptionForm.payment_method"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: {
                            type: "text",
                            name: "payment_method",
                            placeholder: _vm.trans(
                              "subscription.payment_method"
                            )
                          },
                          domProps: {
                            value: _vm.subscriptionForm.payment_method
                          },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.subscriptionForm,
                                "payment_method",
                                $event.target.value
                              )
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c("show-error", {
                          attrs: {
                            "form-name": _vm.subscriptionForm,
                            "prop-name": "payment_method"
                          }
                        })
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-12 col-sm-6" }, [
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v(
                            _vm._s(_vm.trans("subscription.reference_number"))
                          )
                        ]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.subscriptionForm.reference_number,
                              expression: "subscriptionForm.reference_number"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: {
                            type: "text",
                            name: "reference_number",
                            placeholder: _vm.trans(
                              "subscription.reference_number"
                            )
                          },
                          domProps: {
                            value: _vm.subscriptionForm.reference_number
                          },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.subscriptionForm,
                                "reference_number",
                                $event.target.value
                              )
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c("show-error", {
                          attrs: {
                            "form-name": _vm.subscriptionForm,
                            "prop-name": "reference_number"
                          }
                        })
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-12" }, [
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v(_vm._s(_vm.trans("instance.remarks")))
                        ]),
                        _vm._v(" "),
                        _c("autosize-textarea", {
                          attrs: {
                            rows: "2",
                            name: "remarks",
                            placeholder: _vm.trans("instance.remarks")
                          },
                          model: {
                            value: _vm.subscriptionForm.remarks,
                            callback: function($$v) {
                              _vm.$set(_vm.subscriptionForm, "remarks", $$v)
                            },
                            expression: "subscriptionForm.remarks"
                          }
                        }),
                        _vm._v(" "),
                        _c("show-error", {
                          attrs: {
                            "form-name": _vm.subscriptionForm,
                            "prop-name": "remarks"
                          }
                        })
                      ],
                      1
                    )
                  ])
                ]),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass:
                      "btn btn-info waves-effect waves-light pull-right",
                    attrs: { type: "button" },
                    on: { click: _vm.addNewSubscription }
                  },
                  [_vm._v(_vm._s(_vm.trans("general.save")))]
                )
              ]
            )
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }