var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("p", { staticClass: "text-center" }, [
      _c("small", [
        _vm._v(
          " " +
            _vm._s(_vm.trans("general.version") + " " + _vm.getConfig("v")) +
            " " +
            _vm._s(_vm.getConfig("footer_credit"))
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }