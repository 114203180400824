<template>
    <footer class="footer">
        {{trans('general.version')+' '+getConfig('v')}} {{getConfig('footer_credit')}}
    </footer>
</template>

<script>
    export default { 
    	methods: {
    		getConfig(config){
    			return helper.getConfig(config);
    		}
    	}
    }
</script>